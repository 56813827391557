import {
  Icons,
  Modal,
  Upload as AntUpload,
  UploadProps,
} from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";

import useCustomUpload from "hooks/useCustomUpload";
import usePreviewImage from "hooks/usePreviewImage";

const { PlusOutlined } = Icons;

export const UploadImage = ({
  listType = "picture-card",
  children,
  ...rest
}: React.PropsWithChildren<UploadProps>) => {
  const t = useTranslate();

  const { uploadFile } = useCustomUpload();

  const {
    previewOpen,
    previewImage,
    previewTitle,
    handleCancel,
    handlePreview,
  } = usePreviewImage();

  return (
    <>
      <AntUpload
        listType={listType}
        customRequest={uploadFile}
        onPreview={handlePreview}
        {...rest}
      >
        {children || (
          <div>
            <PlusOutlined />
            <div>{t("staffs.upload.description")}</div>
          </div>
        )}
      </AntUpload>

      <Modal
        visible={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="img-preview" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
};
