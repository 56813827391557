import { Col, PageHeader, Row, Show, Typography } from "@pankod/refine-antd";
import {
  IResourceComponentsProps,
  useShow,
  useTranslate,
} from "@pankod/refine-core";
import dayjs from "dayjs";

import TextBox from "components/textBox";
import { DATE_FORMAT, GENDER_LIST } from "config";

import { IStaff } from "interfaces";
import { PersonAccess } from "./private/personAccess";

const { Text } = Typography;

export const StaffShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { queryResult } = useShow<IStaff>();

  const { data, isLoading } = queryResult;

  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Row gutter={[16, 24]}>
        <Col xs={24}>
          <PageHeader
            backIcon={null}
            style={{ padding: 0 }}
            title={
              <Text style={{ fontSize: 16 }}>{t("staffs.basic_header")}</Text>
            }
          />
        </Col>

        <Col xs={24} md={12}>
          <TextBox label={t("staffs.fields.name")} value={record?.fullName} />
        </Col>

        <Col xs={24} md={12}>
          <TextBox label={t("staffs.fields.phone")} value={record?.mobile} />
        </Col>

        <Col xs={24} md={12}>
          <TextBox
            label={t("staffs.fields.department")}
            value={record?.department?.name}
          />
        </Col>

        <Col xs={24} md={12}>
          <TextBox
            label={t("staffs.fields.citizenIdentityNo")}
            value={record?.citizenIdentityNo}
          />
        </Col>

        <Col xs={24} md={12}>
          <TextBox
            label={t("staffs.fields.title")}
            value={record?.title || "--"}
          />
        </Col>

        <Col xs={24} md={12}>
          <TextBox
            label={t("staffs.fields.email")}
            value={record?.email || "--"}
          />
        </Col>

        <Col xs={24} md={12}>
          <TextBox
            label={t("staffs.fields.address")}
            value={record?.address || "--"}
          />
        </Col>

        <Col xs={24} md={12}>
          <TextBox
            label={t("staffs.fields.gender")}
            value={
              GENDER_LIST(t).find((gender) => gender.value === record?.gender)
                ?.label || "--"
            }
          />
        </Col>

        <Col xs={24} md={12}>
          <TextBox
            label={t("staffs.fields.dob")}
            value={record?.dob ? dayjs(record?.dob).format(DATE_FORMAT) : "--"}
          />
        </Col>
      </Row>

      {record?.userAccess && <PersonAccess personAccess={record?.userAccess} />}
    </Show>
  );
};
