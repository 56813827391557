import { useGetIdentity } from "@pankod/refine-core";
import { useNavigate } from "@pankod/refine-react-router-v6";
import { useEffect } from "react";

import { ROLE_BASED_RESOURCES } from "config";

import { IUserIdentity, IUserRoleCode } from "interfaces";

const DashboardPage: React.FC = () => {
  const { data: identity } = useGetIdentity<IUserIdentity>();
  const navigate = useNavigate();

  useEffect(() => {
    if (!identity) return;

    if (!identity?.roles || identity.roles.length === 0) {
      return;
    }

    const role = identity.roles[0] as IUserRoleCode;
    const availableResources = ROLE_BASED_RESOURCES[role];
    if (!availableResources || availableResources.length === 0) {
      return;
    }

    const initialRoute = `/${availableResources[0]}`;
    navigate(initialRoute);
  }, [identity, navigate]);

  return <></>;
};

export default DashboardPage;
