import {
  Col,
  CreateButton,
  DeleteButton,
  EditButton,
  Form,
  Icons,
  Input,
  List,
  Row,
  Space,
  Table,
  useTable,
} from "@pankod/refine-antd";
import {
  CrudFilters,
  HttpError,
  IResourceComponentsProps,
  useTranslate,
} from "@pankod/refine-core";
import { useDebounceFn } from "ahooks";

import { IDevice, IElevator } from "interfaces";

const { SearchOutlined, PlusOutlined } = Icons;

export const ElevatorList: React.FC<IResourceComponentsProps> = ({
  canCreate,
}) => {
  const t = useTranslate();

  const { tableProps, searchFormProps } = useTable<
    IElevator,
    HttpError,
    { q: string }
  >({
    onSearch: ({ q }) => {
      const filters: CrudFilters = [];

      filters.push({
        field: "q",
        operator: "eq",
        value: q,
      });

      return filters;
    },
  });

  const { form, ...restFormProps } = searchFormProps;

  const { run: searchElevators } = useDebounceFn(() => form?.submit(), {
    wait: 300,
  });

  // const { mutate: updateStatus, isLoading: isUpdating } = useUpdate<IDevice>();

  return (
    <List canCreate={false}>
      <Row gutter={16}>
        <Col flex="auto">
          <Form layout="vertical" {...restFormProps} form={form}>
            <Form.Item name="q">
              <Input
                style={{ maxWidth: 500 }}
                placeholder={t("placeholders.search")}
                prefix={<SearchOutlined />}
                onChange={() => searchElevators()}
              />
            </Form.Item>
          </Form>
        </Col>
        <Col>
          {canCreate && (
            <CreateButton type="primary" icon={<PlusOutlined />}>
              {t("buttons.create_new")}
            </CreateButton>
          )}
        </Col>
      </Row>

      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="idx" key="idx" title={"#"} />

        <Table.Column
          dataIndex="name"
          key="name"
          title={t("elevators.fields.name")}
        />

        <Table.Column
          dataIndex="device"
          key="device"
          title={t("elevators.fields.device")}
          render={(device: IDevice) => device?.code}
        />

        <Table.Column
          dataIndex="lift"
          key="lift"
          title={t("elevators.fields.lift")}
        />

        <Table.Column
          dataIndex="floorNumber"
          key="floorNumber"
          title={t("elevators.fields.floorNumber")}
        />

        <Table.Column<IElevator>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <DeleteButton
                hideText
                size="small"
                recordItemId={record.id}
                errorNotification={{
                  type: "error",
                  message: t("elevators.notification.deleteError"),
                }}
                successNotification={{
                  type: "success",
                  message: t("elevators.notification.deleteSuccess"),
                }}
              />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
