import {
  Button,
  ButtonProps,
  Col,
  Modal,
  Row,
  Select,
  Typography,
  useModal,
  useSelect,
  Icons,
} from "@pankod/refine-antd";
import { useState, useEffect } from "react";
import {
  useTranslate,
  useApiUrl,
  useCustomMutation,
} from "@pankod/refine-core";
import { IDevice, IPersonAccess, IScanInputs } from "interfaces";
import TextBox from "components/textBox";
import { fuzzySearch } from "utils/searchHelper";
import { toLowerCaseNonAccentVietnamese } from "utils/vietnameseHelper";

const { Text } = Typography;

const { IdcardOutlined } = Icons;

interface ButtonRegisterCardProps extends ButtonProps {
  userAccess?: IPersonAccess;
  username?: string;
}

export const ButtonRegisterCard: React.FC<ButtonRegisterCardProps> = ({
  userAccess,
  username,
  ...btnProps
}) => {
  const t = useTranslate();

  const {
    selectProps: { onSearch, showSearch, ...restSelectProps },
    queryResult,
  } = useSelect<IDevice>({
    resource: "devices",
    optionLabel: "name",
    pagination: {
      current: 1,
      pageSize: 99999, // use the large PageSize to get full
    },
  });

  const [device, setDevice] = useState<{ label: string; value: string } | null>(
    null
  );

  const apiUrl = useApiUrl();

  const {
    mutate: scanCard,
    isLoading,
    isSuccess,
    reset,
  } = useCustomMutation<IScanInputs>();

  const { modalProps, show, close } = useModal({
    modalProps: {
      title: t("register.register_card"),
      okText: t("buttons.create"),
      cancelText: t("buttons.cancel"),
      maskClosable: false,
      closable: false,
      okButtonProps: {
        loading: isLoading,
        disabled: !device,
      },
      cancelButtonProps: {
        disabled: isLoading,
      },
      onOk: () => {
        const foundDevice = queryResult.data?.data.find(
          (item) => item.id === device?.value
        );

        if (!foundDevice) {
          console.log("not found device", device?.value);
          return;
        }

        scanCard({
          url: `${apiUrl}/cards/scan`,
          method: "post",
          values: {
            deviceCode: foundDevice.code,
            accessCode: userAccess?.accessCode,
          },
          errorNotification: {
            type: "error",
            message: t("register.notification.register_card_error"),
          },
          successNotification: {
            type: "success",
            message: t("register.notification.register_card_success"),
          },
        });
      },
      onCancel: () => {
        setDevice(null);
      },
    },
  });

  useEffect(() => {
    if (isSuccess) {
      close();
      reset();
    }
  }, [close, reset, isSuccess]);

  return (
    <>
      <Button
        onClick={() => show()}
        icon={<IdcardOutlined />}
        size="small"
        children={t("register.card", {
          count: userAccess?.cards?.length ?? 0,
        })}
        {...btnProps}
      />

      <Modal {...modalProps}>
        <Row gutter={[16, 24]}>
          <Col xs={24}>
            <TextBox label={t("register.username")} value={username} />
          </Col>
          <Col xs={24}>
            <TextBox
              label={t("register.accessCode")}
              value={userAccess?.accessCode}
            />
          </Col>
          <Col xs={24}>
            <TextBox
              label={t("register.registered_card_amount")}
              value={String(userAccess?.cards?.length ?? 0)}
            />
          </Col>
          <Col xs={24}>
            <Text style={{ color: "#7f879e" }}>{t("register.devices")}</Text>
            <Select
              labelInValue
              value={device}
              onChange={(v) => setDevice(v)}
              style={{ width: "100%", marginTop: 8 }}
              {...restSelectProps}
              showSearch={true}
              filterOption={(input, option) =>
                fuzzySearch(
                  toLowerCaseNonAccentVietnamese(String(option?.label ?? "")),
                  toLowerCaseNonAccentVietnamese(input)
                )
              }
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
};
