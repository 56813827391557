import { IFloor } from "interfaces";

export function wait(ms: number) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, ms);
  });
}

export function toArray(value: any) {
  return Array.isArray(value) ? value : [value];
}

export const checkScrollToBottom = (element: HTMLElement, offset = 0) => {
  return (
    element.scrollTop + element.clientHeight + offset >= element.scrollHeight
  );
};

export function downloadData(data: any, filename: string) {
  const href = URL.createObjectURL(data);

  const link = document.createElement("a");
  link.href = href;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}

export function sortFloors(floors: IFloor[]) {
  return floors.sort((a, b) =>
    a.floorIndex && b.floorIndex ? a.floorIndex - b.floorIndex : 0
  );
}
