import {
  Col,
  CreateButton,
  DeleteButton,
  EditButton,
  Form,
  Icons,
  Input,
  List,
  Row,
  Space,
  Table,
  useTable,
} from "@pankod/refine-antd";
import {
  CrudFilters,
  HttpError,
  IResourceComponentsProps,
  useTranslate,
} from "@pankod/refine-core";
import { useDebounceFn } from "ahooks";
import { VEHICLE_TYPE_LIST } from "config";

import { IRfid, IStaff, IVehicle } from "interfaces";

const { SearchOutlined, PlusOutlined } = Icons;

export const VehicleList: React.FC<IResourceComponentsProps> = ({
  canCreate,
}) => {
  const t = useTranslate();

  const { tableProps, searchFormProps } = useTable<
    IVehicle,
    HttpError,
    { q: string }
  >({
    onSearch: ({ q }) => {
      const filters: CrudFilters = [];

      filters.push({
        field: "q",
        operator: "eq",
        value: q,
      });

      return filters;
    },
  });

  const { form, ...restFormProps } = searchFormProps;

  const { run: searchDevice } = useDebounceFn(() => form?.submit(), {
    wait: 300,
  });

  // const { mutate: updateStatus, isLoading: isUpdating } = useUpdate<IDevice>();

  return (
    <List canCreate={false}>
      <Row gutter={16}>
        <Col flex="auto">
          <Form layout="vertical" {...restFormProps} form={form}>
            <Form.Item name="q">
              <Input
                style={{ maxWidth: 500 }}
                placeholder={t("placeholders.search")}
                prefix={<SearchOutlined />}
                onChange={() => searchDevice()}
              />
            </Form.Item>
          </Form>
        </Col>
        <Col>
          {canCreate && (
            <CreateButton type="primary" icon={<PlusOutlined />}>
              {t("buttons.create_new")}
            </CreateButton>
          )}
        </Col>
      </Row>

      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="idx" key="idx" title={"#"} />

        <Table.Column
          dataIndex="numberPlate"
          key="numberPlate"
          title={t("vehicles.fields.numberPlate")}
        />

        <Table.Column
          dataIndex="type"
          key="type"
          title={t("vehicles.fields.type")}
          render={(type) =>
            VEHICLE_TYPE_LIST(t).find((it) => it.value === type)?.label
          }
        />

        <Table.Column
          dataIndex="rfid"
          key="rfid"
          title={t("vehicles.fields.rfid")}
          render={(rfid: IRfid) => rfid?.number}
        />

        <Table.Column
          dataIndex="staff"
          key="staff"
          title={t("vehicles.fields.staff")}
          render={(staff: IStaff) => staff?.fullName}
        />

        <Table.Column<IVehicle>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <DeleteButton
                hideText
                size="small"
                recordItemId={record.id}
                errorNotification={{
                  type: "error",
                  message: t("vehicles.notification.deleteError"),
                }}
                successNotification={{
                  type: "success",
                  message: t("vehicles.notification.deleteSuccess"),
                }}
              />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
