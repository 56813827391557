import {
  Col,
  Create,
  Form,
  Input,
  Row,
  Select,
  useForm,
  useSelect,
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useTranslate } from "@pankod/refine-core";
import isIp from "validator/lib/isIP";
import isMACAddress from "validator/lib/isMACAddress";

import { DEVICE_TYPE_LIST } from "config";
import { IArea, IDevice } from "interfaces";
import { fuzzySearch } from "utils/searchHelper";
import { toLowerCaseNonAccentVietnamese } from "utils/vietnameseHelper";

export const DeviceCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { formProps, saveButtonProps, formLoading } = useForm<IDevice>({
    errorNotification: {
      type: "error",
      message: t("devices.notification.createError"),
    },
    successNotification: {
      type: "success",
      message: t("devices.notification.createSuccess"),
    },
  });

  const {
    selectProps: { showSearch, onSearch, ...restSelectProps },
  } = useSelect<IArea>({
    resource: "areas",
    optionLabel: "name",
    pagination: {
      current: 1,
      pageSize: 99999, // use the large PageSize to get full
    },
  });

  return (
    <Create saveButtonProps={saveButtonProps} isLoading={formLoading}>
      <Form {...formProps} layout="vertical">
        <Row gutter={[16, 24]}>
          <Col xs={24} md={12}>
            <Form.Item
              label={t("devices.fields.type")}
              name="type"
              rules={[
                {
                  required: true,
                  message: t("devices.errors.typeRequired"),
                },
              ]}
            >
              <Select
                options={DEVICE_TYPE_LIST(t).filter((it) => !it.disabledCreate)}
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              label={t("devices.fields.area")}
              name={"area"}
              rules={[
                {
                  required: true,
                  message: t("devices.errors.areaRequired"),
                },
              ]}
            >
              <Select
                {...restSelectProps}
                showSearch={true}
                filterOption={(input, option) =>
                  fuzzySearch(
                    toLowerCaseNonAccentVietnamese(String(option?.label ?? "")),
                    toLowerCaseNonAccentVietnamese(input)
                  )
                }
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              label={t("devices.fields.name")}
              name="name"
              rules={[
                {
                  required: true,
                  message: t("devices.errors.nameRequired"),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              label={t("devices.fields.code")}
              name="code"
              rules={[
                {
                  required: true,
                  message: t("devices.errors.codeRequired"),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              label={t("devices.fields.ip")}
              name="ip"
              rules={[
                {
                  validator: (_, value) => {
                    if (!value || isIp(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(t("devices.errors.ipInvalid"))
                    );
                  },
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              label={t("devices.fields.mac")}
              name="mac"
              rules={[
                {
                  validator: (_, value) => {
                    if (!value || isMACAddress(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(t("devices.errors.macInvalid"))
                    );
                  },
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item label={t("devices.fields.firmware")} name="firmware">
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item label={t("devices.fields.hardware")} name="hardware">
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item label={t("devices.fields.kernel")} name="kernel">
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <></>
          </Col>

          <Col xs={24}>
            <Form.Item
              label={t("devices.fields.description")}
              name="description"
            >
              <Input.TextArea rows={6} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Create>
  );
};
