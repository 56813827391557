import {
  IDeviceConnectState,
  IDeviceType,
  IGenderCode,
  IUserRoleCode,
  IVehicleType,
  IVisitorRegistrationStatus,
} from "interfaces";

export const HOST_API = process.env.REACT_APP_HOST_API || ""; //"https://api.fake-rest.refine.dev";

export const ACCESS_TOKEN_KEY = "ACCESS_TOKEN_KEY";
export const REFRESH_TOKEN_KEY = "REFRESH_TOKEN_KEY";
export const USER_ID_KEY = "USER_ID_KEY";
export const USER_NAME_KEY = "USER_NAME_KEY";
export const USER_USERNAME_KEY = "USER_USERNAME_KEY";
export const USER_AVATAR_KEY = "USER_AVATAR_KEY";
export const USER_ROLE_KEY = "USER_ROLE_KEY";

export const PASSWORD_PLACEHOLDER = "●●●●●●●●";

export const DATE_FORMAT = "DD/MM/YYYY";
export const DATE_TIME_FORMAT = "DD/MM/YYYY HH:mm";
export const FULL_DATE_TIME_FORMAT = "DD/MM/YYYY HH:mm:ss";

export const DISABLE_SIDER_LOGOUT = true;
export const DISABLE_SIDER_DASHBOARD = true;

export const DEFAULT_COUNT_DATE_EXPORT = 30;

export const ROLE_LIST = (
  t?: Function
): {
  value: IUserRoleCode;
  label: string;
  color: string;
}[] => {
  return [
    {
      value: "ADMIN",
      label: t ? t("roles.admin") : "Admin",
      color: "#172B4D",
    },
    {
      value: "DEPARTMENT",
      label: t ? t("roles.department") : "Quản lý phòng ban",
      color: "#34A853",
    },
    {
      value: "SECURITY_DEPARTMENT",
      label: t ? t("roles.security_department") : "Quản lý an ninh",
      color: "#faad14",
    },
  ];
};

export const DEVICE_TYPE_LIST = (
  t?: Function
): {
  value: IDeviceType;
  label: string;
  disabledCreate?: boolean;
}[] => {
  return [
    {
      value: "ACCESS_CONTROL",
      label: t ? t("devices.type.ac") : "Thiết bị Kiểm soát vào ra",
      disabledCreate: true,
    },
    {
      value: "CARD_READER",
      label: t ? t("devices.type.uhf_reader") : "Thiết bị đọc UHF",
    },
    {
      value: "CAMERA",
      label: t ? t("devices.type.camera") : "Camera",
    },
    {
      value: "AIBOX",
      label: t ? t("devices.type.aibox") : "AI Box",
    },
    {
      value: "ELEVATOR_CONTROL",
      label: t ? t("devices.type.elevator") : "Thiết bị điều khiển thang máy",
      disabledCreate: true,
    },
  ];
};

export const GENDER_LIST = (
  t?: Function
): {
  value: IGenderCode;
  label: string;
}[] => {
  return [
    {
      value: "MALE",
      label: t ? t("gender.male") : "Nam",
    },
    {
      value: "FEMALE",
      label: t ? t("gender.female") : "Nữ",
    },
    {
      value: "OTHER",
      label: t ? t("gender.other") : "Khác",
    },
  ];
};

export const VISITOR_REGISTRATION_STATUS_LIST = (
  t?: Function
): {
  value: IVisitorRegistrationStatus;
  label: string;
  color: string;
}[] => {
  return [
    {
      value: "created",
      label: t ? t("visitors_registrations.fields.status_created") : "Đang tạo",
      color: "default",
    },
    {
      value: "wait_for_approved",
      label: t
        ? t("visitors_registrations.fields.status_wait_for_approved")
        : "Chờ phê duyệt",
      color: "blue",
    },
    {
      value: "approved",
      label: t
        ? t("visitors_registrations.fields.status_approved")
        : "Đã phê duyệt",
      color: "success",
    },
    {
      value: "rejected",
      label: t
        ? t("visitors_registrations.fields.status_rejected")
        : "Đã từ chối",
      color: "error",
    },
  ];
};

export const VEHICLE_TYPE_LIST = (
  t?: Function
): {
  value: IVehicleType;
  label: string;
}[] => {
  return [
    {
      value: "CAR",
      label: t ? t("vehicles.types.car") : "Ô tô",
    },
    {
      value: "MOTORBIKE",
      label: t ? t("vehicles.types.motorbike") : "Xe máy",
    },
  ];
};

export const DEVICE_CONNECTION_STATE_LIST = (
  t?: Function
): {
  value: IDeviceConnectState;
  label: string;
}[] => {
  return [
    {
      value: "CONNECTED",
      label: t ? t("devices.fields.status_true") : "Kết nối",
    },
    {
      value: "DISCONNECTED",
      label: t ? t("devices.fields.status_false") : "Mất kết nối",
    },
  ];
};

export const DEVICE_LOG_EVENT_TYPES = [
  "FACE_AUTHENTICATION_SUCCESS",
  "FINGER_AUTHENTICATION_SUCCESS",
  "CARD_AUTHENTICATION_SUCCESS",
  "FACE_AUTHENTICATION_FAILURE",
  "FINGER_AUTHENTICATION_FAILURE",
  "CARD_AUTHENTICATION_FAILURE",
  "ACCESS_DENIED",
  "USER_ENROLLMENT_SUCCESS",
  "USER_ENROLLMENT_FAILURE",
  "USER_UPDATE_SUCCESS",
  "USER_UPDATE_FAILURE",
  "USER_DELETE_SUCCESS",
  "USER_DELETE_FAILURE",
  "USER_DELETE_ALL_USER_SUCCESS",
  "USER_DUPLICATE_FACE",
  "USER_DUPLICATE_FINGER",
  "USER_DUPLICATE_CARD",
  "DEVICE_SYSTEM_RESET",
  "DEVICE_SYSTEM_STARTED",
  "DEVICE_TCP_CONNECTED",
  "DEVICE_TCP_DISCONNECTED",
  "DEVICE_EVENT_LOG_CLEARED",
  "DEVICE_CONFIG_RESET",
  "DEVICE_FACTORY_RESET",
  "DOOR_LOCK",
  "DOOR_LOCKED",
  "DOOR_UNLOCK",
  "DOOR_UNLOCKED",
  "DOOR_OPENED",
  "DOOR_CLOSED",
  "DOOR_FORCED_OPEN",
  "DOOR_RELEASE",
];

export const USER_ACCESS_TYPES = ["VISITOR", "STAFF"];

export const PATHS = {
  home: "/",
  forgotPassword: "/forgot-password",
  updatePassword: "/update-password",
  login: "/login",
  accountMe: "/account/me",
};

export const API_RESOURCES = {
  areas: "areas",
  departments: "departments",
  devices: "devices",
  rfids: "rfids",
  elevators: "elevators",
  vehicles: "vehicles",
  users: "users",
  staffs: "staffs",
  visitorsRegistrations: "visitors_registrations",
  deviceLogs: "device_logs",
};

export const ROLE_BASED_RESOURCES: Record<IUserRoleCode, string[]> = {
  ADMIN: [
    API_RESOURCES.users,
    API_RESOURCES.staffs,
    API_RESOURCES.departments,
    API_RESOURCES.areas,
    API_RESOURCES.devices,
    API_RESOURCES.rfids,
    API_RESOURCES.elevators,
    API_RESOURCES.vehicles,
    API_RESOURCES.visitorsRegistrations,
    API_RESOURCES.deviceLogs,
  ],
  DEPARTMENT: [API_RESOURCES.departments, API_RESOURCES.visitorsRegistrations],
  SECURITY_DEPARTMENT: [
    API_RESOURCES.departments,
    API_RESOURCES.visitorsRegistrations,
    API_RESOURCES.deviceLogs,
  ],
};
