import { Icons, Popover } from "@pankod/refine-antd";
import {
  useGetIdentity,
  useNavigation,
  useTranslate,
} from "@pankod/refine-core";

import { PATHS, ROLE_LIST } from "config";
import { IUserIdentity } from "interfaces";
import { useMemo } from "react";
import { MenuPopoverContent } from "./MenuPopoverContent";
import { UserAvatar } from "./UserAvatar";

const { CaretDownFilled } = Icons;

export const MenuPopover: React.FC = () => {
  const t = useTranslate();
  const { data: user } = useGetIdentity<IUserIdentity>();
  const { push } = useNavigation();

  const displayName = user?.name || user?.username || t("menu.popover.unknown");
  const roleName = useMemo(() => {
    if (!user?.roles?.length) return t("menu.popover.unknown");

    return user.roles
      .map((role) => ROLE_LIST(t).find((item) => item.value === role)?.label)
      .join(",");
  }, [user, t]);

  return (
    <Popover
      title=""
      trigger="click"
      showArrow={false}
      placement="bottomRight"
      content={
        <MenuPopoverContent
          name={displayName}
          role={roleName}
          avatar={user?.avatar}
          onChangePasswordClicked={() => {
            if (window.location.pathname !== PATHS.updatePassword) {
              push(PATHS.updatePassword);
            }
          }}
          // onAccountInfoClicked={() => {
          //   if (window.location.pathname !== PATHS.accountMe) {
          //     push(PATHS.accountMe);
          //   }
          // }}
        />
      }
    >
      <span style={{ cursor: "pointer" }}>
        <UserAvatar size={32} src={user?.avatar} name={displayName} />
        <CaretDownFilled style={{ color: "#7F879E", marginLeft: 3 }} />
      </span>
    </Popover>
  );
};
