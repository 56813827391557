import { Card, Col, Icons, Row, Space, Typography } from "@pankod/refine-antd";
import { useLogout, useTranslate } from "@pankod/refine-core";
import { PATHS } from "config";
import { UserAvatar } from "./UserAvatar";

const { UserOutlined, LogoutOutlined, EditOutlined } = Icons;
const { Text } = Typography;

type MenuPopoverContentProps = {
  name: string;
  role: string;
  avatar?: string;
  onAccountInfoClicked?: () => void;
  onChangePasswordClicked?: () => void;
};

export const MenuPopoverContent: React.FC<MenuPopoverContentProps> = ({
  avatar,
  role,
  name,
  onAccountInfoClicked,
  onChangePasswordClicked,
}) => {
  const { mutate: logout } = useLogout<{ redirectPath: string }>();
  const t = useTranslate();

  return (
    <Card
      bordered={false}
      style={{ width: 300 }}
      bodyStyle={{ padding: "20px 24px" }}
    >
      <Row gutter={14} style={{ paddingTop: 8, paddingBottom: 16 }}>
        <Col flex="54px">
          <UserAvatar size={50} src={avatar} name={name} />
        </Col>
        <Col flex="auto">
          <Space direction="vertical">
            <Row>
              <Text ellipsis strong>
                {name}
              </Text>
            </Row>
            <Row>
              <Text>{role}</Text>
            </Row>
          </Space>
        </Col>
      </Row>

      {onAccountInfoClicked && (
        <Row
          style={{ paddingTop: 6, paddingBottom: 6, cursor: "pointer" }}
          onClick={onAccountInfoClicked}
        >
          <Space>
            <UserOutlined />
            <Text>{t("menu.popover.account_info")}</Text>
          </Space>
        </Row>
      )}

      {onChangePasswordClicked && (
        <Row
          style={{ paddingTop: 6, paddingBottom: 6, cursor: "pointer" }}
          onClick={onChangePasswordClicked}
        >
          <Space>
            <EditOutlined />
            <Text>{t("menu.popover.change_password")}</Text>
          </Space>
        </Row>
      )}

      <Row
        style={{ paddingTop: 6, paddingBottom: 6, cursor: "pointer" }}
        onClick={() =>
          logout({
            redirectPath: PATHS.home,
          })
        }
      >
        <Space>
          <LogoutOutlined style={{ color: "#E60019" }} />
          <Text type="danger">{t("menu.popover.logout")}</Text>
        </Space>
      </Row>
    </Card>
  );
};
