import { AuthProvider } from "@pankod/refine-core";
import axiosInstance from "axiosInstance";
import {
  ACCESS_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
  USER_ID_KEY,
  USER_NAME_KEY,
  USER_AVATAR_KEY,
  USER_ROLE_KEY,
  HOST_API,
  USER_USERNAME_KEY,
  PATHS,
} from "config";
import { IUserIdentity } from "interfaces";
import { getUserRoles, removeAllUserIdentities } from "utils/identityHelper";

export const authProvider: AuthProvider = {
  login: async ({ username, password, translate }) => {
    try {
      const { data } = await axiosInstance.post(
        `${HOST_API}/users/authenticate`,
        {
          username,
          password,
        }
      );

      localStorage.setItem(ACCESS_TOKEN_KEY, data.accessToken || "");
      localStorage.setItem(REFRESH_TOKEN_KEY, data.refreshToken || "");
      localStorage.setItem(USER_ID_KEY, data.user?.id || "");
      localStorage.setItem(USER_NAME_KEY, data.user?.name || "");
      localStorage.setItem(USER_USERNAME_KEY, username || "");
      localStorage.setItem(USER_AVATAR_KEY, data.user?.avatar || "");
      localStorage.setItem(USER_ROLE_KEY, JSON.stringify(data.role || []));

      return Promise.resolve();
    } catch (_) {
      const error = new Error(translate("pages.login.notification.loginError"));
      error.name = translate("pages.login.notification.wrongUserPass");
      return Promise.reject(error);
    }
  },
  logout: async () => {
    try {
      await axiosInstance.post(`${HOST_API}/users/logout`);
    } catch (error) {
      console.log("logout error", error);
    } finally {
      removeAllUserIdentities();
      return Promise.resolve(PATHS.home);
    }
  },
  checkError: () => Promise.resolve(),
  checkAuth: () => {
    const token = localStorage.getItem(ACCESS_TOKEN_KEY);
    if (token) {
      return Promise.resolve();
    }

    return Promise.reject();
  },
  getPermissions: () => Promise.resolve(),
  getUserIdentity: async (): Promise<IUserIdentity> => {
    const token = localStorage.getItem(ACCESS_TOKEN_KEY);
    if (!token) {
      return Promise.reject();
    }

    return Promise.resolve({
      id: localStorage.getItem(USER_ID_KEY) || "",
      name: localStorage.getItem(USER_NAME_KEY) || "",
      username: localStorage.getItem(USER_USERNAME_KEY) || "",
      avatar: localStorage.getItem(USER_AVATAR_KEY) || "",
      roles: getUserRoles(),
    });
  },
};
