import {
  CreateButton,
  DeleteButton,
  EditButton,
  Icons,
  List,
  Space,
  // Switch,
  Table,
  TextField,
  useTable,
} from "@pankod/refine-antd";
import {
  CrudFilters,
  HttpError,
  IResourceComponentsProps,
  useGetIdentity,
  useTranslate,
} from "@pankod/refine-core";

import { ROLE_LIST } from "config";
import { IUser, IUserIdentity, IUserRole } from "interfaces";

const {
  // SearchOutlined,
  PlusOutlined,
} = Icons;

export const UserList: React.FC<IResourceComponentsProps> = ({ canCreate }) => {
  const t = useTranslate();

  const { data: user } = useGetIdentity<IUserIdentity>();

  const {
    tableProps,
    // searchFormProps
  } = useTable<IUser, HttpError, { q: string }>({
    onSearch: ({ q }) => {
      const filters: CrudFilters = [];

      filters.push({
        field: "q",
        operator: "eq",
        value: q,
      });

      return filters;
    },
  });

  // const { form, ...restFormProps } = searchFormProps;

  // const { run: searchUser } = useDebounceFn(() => form?.submit(), {
  //   wait: 300,
  // });

  // const { mutate: updateActivated, isLoading: isUpdating } = useUpdate<IUser>();

  return (
    <List
      headerButtons={
        canCreate ? (
          <CreateButton type="primary" icon={<PlusOutlined />}>
            {t("buttons.create_new")}
          </CreateButton>
        ) : null
      }
    >
      {/* <Row gutter={16}>
        <Col flex="auto">
          <Form layout="vertical" {...restFormProps} form={form}>
            <Form.Item name="q">
              <Input
                style={{ maxWidth: 500 }}
                placeholder={t("placeholders.search")}
                prefix={<SearchOutlined />}
                onChange={() => searchUser()}
              />
            </Form.Item>
          </Form>
        </Col>
        <Col>
          {canCreate && (
            <CreateButton type="primary" icon={<PlusOutlined />}>
              {t("buttons.create_new")}
            </CreateButton>
          )}
        </Col>
      </Row> */}

      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="idx" key="idx" title={"#"} />

        <Table.Column
          dataIndex="username"
          key="username"
          title={t("users.fields.username")}
        />

        <Table.Column
          dataIndex="roles"
          key="role"
          title={t("users.fields.role")}
          render={(roles) => {
            if (!roles?.length) return "--";

            if (roles.length === 1) {
              const roleItem = ROLE_LIST(t).find(
                (it) => it.value === roles[0].code
              );

              return (
                <TextField
                  value={roleItem?.label || "--"}
                  style={{ color: roleItem?.color || "unset" }}
                />
              );
            }

            return (
              <TextField
                value={
                  roles
                    ?.map(
                      (role: IUserRole) =>
                        ROLE_LIST(t).find((it) => it.value === role.code)?.label
                    )
                    .join(",") ?? "--"
                }
              />
            );
          }}
        />

        <Table.Column
          dataIndex={["department", "name"]}
          key="department"
          title={t("users.fields.department")}
        />

        {/* <Table.Column<IUser>
          dataIndex="user-activated"
          key="user-activated"
          title={t("users.fields.activated")}
          render={(_, record) => (
            <Switch
              loading={isUpdating}
              checked={record.activated}
              onChange={() => {
                updateActivated({
                  resource: API_RESOURCES.users,
                  id: record.username || "",
                  values: { ...record, activated: !record.activated },
                  errorNotification: {
                    type: "error",
                    message: t("users.notification.editError"),
                  },
                  successNotification: {
                    type: "success",
                    message: t("users.notification.editSuccess"),
                  },
                });
              }}
            />
          )}
        /> */}

        <Table.Column<IUser>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton
                hideText
                size="small"
                recordItemId={record.id}
                disabled={record.id === user?.id}
              />
              {record.username !== user?.username && (
                <DeleteButton
                  hideText
                  size="small"
                  recordItemId={record.id}
                  disabled={record.username === user?.username}
                  errorNotification={{
                    type: "error",
                    message: t("users.notification.deleteError"),
                  }}
                  successNotification={{
                    type: "success",
                    message: t("users.notification.deleteSuccess"),
                  }}
                />
              )}
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
