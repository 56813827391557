import { Button, Icons, Popconfirm, Space, Table } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { ModalPreviewImage } from "components/previewImage";
import {
  ButtonRegisterCard,
  ButtonRegisterFace,
  ButtonRegisterFinger,
} from "components/registers";
import useUserRole from "hooks/useUserRole";

import { IVisitor, IVisitorRegistrationStatus } from "interfaces";

const { EditOutlined, DeleteOutlined } = Icons;

interface VisitorTableProps {
  visitorList: IVisitor[];
  registrationStatus?: IVisitorRegistrationStatus;
  onEdit?: (visitor: IVisitor) => void;
  onDelete?: (visitor: IVisitor) => void;
}

export const VisitorTable: React.FC<VisitorTableProps> = ({
  visitorList = [],
  registrationStatus = "created",
  onEdit,
  onDelete,
}) => {
  const t = useTranslate();

  const { hasSecurityRole } = useUserRole();

  // const invalidate = useInvalidate();

  // const handleUpdateAllowedAreaSuccess = useCallback(() => {
  //   invalidate({
  //     resource: "visitors_registrations",
  //     invalidates: ["all"],
  //   });
  // }, [invalidate]);

  return (
    <Table rowKey="key" dataSource={visitorList}>
      <Table.Column dataIndex="key" key="key" title={"#"} />

      <Table.Column
        dataIndex="fullName"
        key="fullName"
        title={t("visitors_registrations.visitorList.fields.name")}
      />

      <Table.Column
        dataIndex="citizenIdentityNo"
        key="code"
        title={t("visitors_registrations.visitorList.fields.citizenIdentityNo")}
      />

      <Table.Column
        dataIndex="mobile"
        key="code"
        title={t("visitors_registrations.visitorList.fields.mobile")}
      />

      <Table.Column
        dataIndex="address"
        key="address"
        title={t("visitors_registrations.visitorList.fields.address")}
      />

      <Table.Column
        dataIndex="vehicle_id"
        key="vehicle_id"
        title={t("visitors_registrations.visitorList.fields.vehicle_id")}
      />

      <Table.Column<IVisitor>
        dataIndex="idImage"
        key="idImage"
        title={t("visitors_registrations.visitorList.fields.image")}
        render={(_, record) => {
          console.log({ record });
          return (
            <Space direction="horizontal" size={12}>
              {record.citizenIdFontImage && (
                <ModalPreviewImage
                  url={record.citizenIdFontImage}
                  name={t(
                    "visitors_registrations.visitorList.fields.citizenIdFontImage"
                  )}
                />
              )}
              {record.citizenIdBackImage && (
                <ModalPreviewImage
                  url={record.citizenIdBackImage}
                  name={t(
                    "visitors_registrations.visitorList.fields.citizenIdBackImage"
                  )}
                />
              )}
            </Space>
          );
        }}
      />

      {hasSecurityRole && registrationStatus === "approved" && (
          <>
            <Table.Column<IVisitor>
              dataIndex="registers"
              title={t("visitors_registrations.visitorList.fields.registers")}
              width={180}
              render={(_, record) => (
                <Space wrap>
                  <ButtonRegisterCard
                    userAccess={record.userAccess}
                    username={record.fullName}
                  />
                  <ButtonRegisterFace
                    userAccess={record.userAccess}
                    username={record.fullName}
                  />
                  <ButtonRegisterFinger
                    userAccess={record.userAccess}
                    username={record.fullName}
                  />
                </Space>
              )}
            />

            {/* <Table.Column<IVisitor>
              dataIndex="allowed_areas"
              key="allowed_areas"
              title={t(
                "visitors_registrations.visitorList.fields.allowed_areas"
              )}
              render={(_, record) => (
                <ButtonSelectAllowedAreas
                  userAccess={record.userAccess}
                  onSuccess={handleUpdateAllowedAreaSuccess}
                />
              )}
            /> */}
          </>
        )}

      {(onEdit || onDelete) && (
        <Table.Column<IVisitor>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              {onEdit && (
                <Button
                  icon={<EditOutlined />}
                  size="small"
                  onClick={() => onEdit?.(record)}
                />
              )}
              {onDelete && (
                <Popconfirm
                  title={t("buttons.confirm")}
                  onConfirm={() => onDelete?.(record)}
                  onCancel={() => null}
                  okText={t("buttons.clear")}
                  cancelText={t("buttons.cancel")}
                  okType="danger"
                >
                  <Button icon={<DeleteOutlined />} danger size="small" />
                </Popconfirm>
              )}
            </Space>
          )}
        />
      )}
    </Table>
  );
};
