import {
  Col,
  Create,
  Form,
  Input,
  Row,
  Select,
  useForm,
  useSelect,
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useTranslate } from "@pankod/refine-core";
import { VEHICLE_TYPE_LIST } from "config";

import { IRfid, IStaff, IVehicle } from "interfaces";
import { fuzzySearch } from "utils/searchHelper";
import { toLowerCaseNonAccentVietnamese } from "utils/vietnameseHelper";

export const VehicleCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { formProps, saveButtonProps, formLoading } = useForm<IVehicle>({
    errorNotification: {
      type: "error",
      message: t("vehicles.notification.createError"),
    },
    successNotification: {
      type: "success",
      message: t("vehicles.notification.createSuccess"),
    },
  });

  const {
    selectProps: {
      showSearch: showSearchRfid,
      onSearch: onSearchRfid,
      ...restSelectRfidProps
    },
  } = useSelect<IRfid>({
    resource: "rfids",
    optionLabel: "number",
    pagination: {
      current: 1,
      pageSize: 99999, // use the large PageSize to get full
    },
  });

  const {
    selectProps: {
      // showSearch: showSearchStaff,
      // onSearch: onSearchStaff,
      ...restSelectStaffProps
    },
  } = useSelect<IStaff>({
    resource: "staffs",
    optionLabel: "fullName",
    pagination: {
      current: 1,
      pageSize: 99999, // use the large PageSize to get full
    },
    onSearch: (value) => [
      {
        field: "q",
        operator: "eq",
        value,
      },
    ],
  });

  return (
    <Create saveButtonProps={saveButtonProps} isLoading={formLoading}>
      <Form {...formProps} layout="vertical">
        <Row gutter={[16, 24]}>
          <Col xs={24} md={12}>
            <Form.Item
              label={t("vehicles.fields.numberPlate")}
              name="numberPlate"
              rules={[
                {
                  required: true,
                  message: t("vehicles.errors.numberPlateRequired"),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              label={t("vehicles.fields.type")}
              name="type"
              rules={[
                {
                  required: true,
                  message: t("vehicles.errors.typeRequired"),
                },
              ]}
            >
              <Select options={VEHICLE_TYPE_LIST(t)} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item label={t("vehicles.fields.rfid")} name={"rfid"}>
              <Select
                {...restSelectRfidProps}
                showSearch={true}
                filterOption={(input, option) =>
                  fuzzySearch(
                    toLowerCaseNonAccentVietnamese(String(option?.label ?? "")),
                    toLowerCaseNonAccentVietnamese(input)
                  )
                }
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item label={t("vehicles.fields.staff")} name={"staffId"}>
              <Select
                {...restSelectStaffProps}
                showSearch={true}
                filterOption={(input, option) =>
                  fuzzySearch(
                    toLowerCaseNonAccentVietnamese(String(option?.label ?? "")),
                    toLowerCaseNonAccentVietnamese(input)
                  )
                }
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Create>
  );
};
