import {
  Col,
  Edit,
  Form,
  Input,
  Row,
  Select,
  useForm,
  useSelect,
} from "@pankod/refine-antd";
import {
  BaseRecord,
  IResourceComponentsProps,
  useApiUrl,
  useCustom,
  useTranslate,
} from "@pankod/refine-core";

import { IDevice, IDeviceLifts, IElevator } from "interfaces";
import { useEffect, useMemo } from "react";
import { fuzzySearch } from "utils/searchHelper";
import { toLowerCaseNonAccentVietnamese } from "utils/vietnameseHelper";

export const ElevatorEdit: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const {
    formProps: { form, ...restFormProps },
    saveButtonProps,
    formLoading,
    queryResult,
  } = useForm<IElevator>({
    errorNotification: {
      type: "error",
      message: t("elevators.notification.editError"),
    },
    successNotification: {
      type: "success",
      message: t("elevators.notification.editSuccess"),
    },
  });

  useEffect(() => {
    if (form && queryResult?.data?.data.device) {
      form.setFieldsValue({
        deviceCode: queryResult.data.data.device.code,
      });
    }
  }, [form, queryResult?.data?.data.device]);

  const {
    selectProps: {
      showSearch: showSearchDevice,
      onSearch: onSearchDevice,
      ...restSelectDeviceProps
    },
  } = useSelect<IDevice>({
    resource: "devices",
    optionLabel: "code",
    optionValue: "code",
    pagination: {
      current: 1,
      pageSize: 99999, // use the large PageSize to get full
    },
  });

  const deviceCode = Form.useWatch("deviceCode", form);

  const apiUrl = useApiUrl();

  const { data: liftData, isLoading: isLoadingLift } = useCustom<BaseRecord>({
    url: `${apiUrl}/devices/${deviceCode}/lift`,
    method: "get",
    queryOptions: {
      enabled: Boolean(deviceCode),
    },
  });

  const liftOptions = useMemo(() => {
    const deviceLifts = liftData?.data as IDeviceLifts | null | undefined;
    return (
      deviceLifts?.lifts?.map((lift) => ({
        value: lift.deviceId,
        label: lift.deviceId,
      })) ?? []
    );
  }, [liftData?.data]);

  return (
    <Edit saveButtonProps={saveButtonProps} isLoading={formLoading}>
      <Form form={form} {...restFormProps} layout="vertical">
        <Row gutter={[16, 24]}>
          <Col xs={24} md={12}>
            <Form.Item
              label={t("elevators.fields.name")}
              name="name"
              rules={[
                {
                  required: true,
                  message: t("elevators.errors.nameRequired"),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              label={t("elevators.fields.device")}
              name="deviceCode"
              rules={[
                {
                  required: true,
                  message: t("elevators.errors.deviceRequired"),
                },
              ]}
            >
              <Select
                {...restSelectDeviceProps}
                showSearch={true}
                filterOption={(input, option) =>
                  fuzzySearch(
                    toLowerCaseNonAccentVietnamese(String(option?.label ?? "")),
                    toLowerCaseNonAccentVietnamese(input)
                  )
                }
                onChange={() => {
                  form?.resetFields(["lift"])
                }}
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              label={t("elevators.fields.lift")}
              name="lift"
              rules={[
                {
                  required: true,
                  message: t("elevators.errors.liftRequired"),
                },
              ]}
            >
              <Select
                loading={isLoadingLift && deviceCode}
                options={liftOptions}
                disabled={!deviceCode}
                showSearch={true}
                filterOption={(input, option) =>
                  fuzzySearch(
                    toLowerCaseNonAccentVietnamese(String(option?.label ?? "")),
                    toLowerCaseNonAccentVietnamese(input)
                  )
                }
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              label={t("elevators.fields.floorNumber")}
              name="floorNumber"
              rules={[
                {
                  required: true,
                  message: t("elevators.errors.floorNumberRequired"),
                },
                {
                  validator: (_, value) => {
                    if (!value || Number.isSafeInteger(parseInt(value))) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(t("elevators.errors.floorNumberInvalid"))
                    );
                  },
                },
              ]}
            >
              <Input type="number" min={0} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};
