import {
  Button,
  Col,
  Edit,
  Form,
  Icons,
  Input,
  Modal,
  PageHeader,
  Row,
  useForm,
  useModalForm,
  Typography,
  Select,
  useSelect,
} from "@pankod/refine-antd";
import {
  useApiUrl,
  useCustomMutation,
  useNavigation,
} from "@pankod/refine-core";
import { IResourceComponentsProps, useTranslate } from "@pankod/refine-core";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";

import DatePicker from "components/datepicker";
import { DATE_TIME_FORMAT } from "config";
import { IArea, IVisitor, IVisitorRegistration } from "interfaces";
import { AddVisitorFormContent } from "./private/addVisitorFormContent";
import { VISITORS_KEY } from "./private/constants";
import { VisitorTable } from "./private/visitorTable";
import { fuzzySearch } from "utils/searchHelper";
import { toLowerCaseNonAccentVietnamese } from "utils/vietnameseHelper";

const { PlusOutlined } = Icons;
const { Text } = Typography;

export const VisitorRegistrationEdit: React.FC<
  IResourceComponentsProps
> = () => {
  const t = useTranslate();

  const { list } = useNavigation();

  const {
    formProps: { form: formRegistration, ...restFormRegistration },
    saveButtonProps,
    formLoading,
    queryResult,
  } = useForm<IVisitorRegistration>({
    errorNotification: {
      type: "error",
      message: t("visitors_registrations.notification.editError"),
    },
    successNotification: {
      type: "success",
      message: t("visitors_registrations.notification.editSuccess"),
    },
  });

  const {
    modalProps,
    formProps: { onFinish: _, form: formAddVisitor, ...restModalFormProps },
    show: showAddVisitor,
    close: closeAddVisitor,
    visible: visibleAddVisitor,
  } = useModalForm({
    action: "edit",
  });

  const initVisitors = useMemo(
    () => queryResult?.data?.data.visitors || [],
    [queryResult]
  );

  const [visitorList, setVisitorList] = useState<IVisitor[]>(initVisitors);

  useEffect(() => {
    setVisitorList(
      initVisitors.map((visitor, index) => ({
        ...visitor,
        key: index + 1,
      }))
    );
  }, [initVisitors]);

  const addOrEditVisitor = async ({ key, ...rest }: IVisitor) => {
    const newVisitorList = !key
      ? visitorList.concat({
          key: visitorList.length + 1,
          ...rest,
        })
      : visitorList.map((visitor) => {
          if (visitor.key === key) {
            return {
              key,
              ...rest,
            };
          }
          return visitor;
        });

    setVisitorList(newVisitorList);
    formRegistration?.setFieldsValue({ [VISITORS_KEY]: newVisitorList });

    closeAddVisitor();
    formAddVisitor?.resetFields();
  };

  const deleteVisitor = async (record: IVisitor) => {
    const newVisitorList = visitorList
      .filter((visitor) => visitor.key !== record.key)
      .map((visitor, index) => ({
        ...visitor,
        key: index + 1,
      }));

    setVisitorList(newVisitorList);
    formRegistration?.setFieldsValue({ [VISITORS_KEY]: newVisitorList });
  };

  useEffect(() => {
    if (queryResult && formRegistration) {
      formRegistration?.setFieldsValue({
        area: queryResult?.data?.data?.area?.map((area) => area?.id),
      });
    }
  }, [formRegistration, queryResult]);

  const {
    selectProps: { onSearch, showSearch, ...restSelectAreaProps },
  } = useSelect<IArea>({
    resource: "areas",
    optionLabel: "name",
    pagination: {
      current: 1,
      pageSize: 99999, // use the large PageSize to get full
    },
  });

  const apiUrl = useApiUrl();

  const { mutate: updateStaff } = useCustomMutation<IVisitorRegistration>();

  const handleUpdateVisitorRegistration = async (
    props: IVisitorRegistration
  ) => {
    updateStaff(
      {
        url: `${apiUrl}/visitors_registrations/update`,
        method: "put",
        values: {
          ...props,
          id: queryResult?.data?.data?.id,
          area: props.area?.map((area) => ({
            id: area,
          })),
        },
        errorNotification: {
          type: "error",
          message: t("visitors_registrations.notification.editError"),
        },
        successNotification: {
          type: "success",
          message: t("visitors_registrations.notification.editSuccess"),
        },
      },
      {
        onError: (error, variables, context) => {
          console.log("handleUpdateVisitorRegistration", {
            error,
            variables,
            context,
          });
        },
        onSuccess: (data, variables, context) => {
          console.log("handleUpdateVisitorRegistration", {
            data,
            variables,
            context,
          });
          list("visitors_registrations");
        },
      }
    );
  };

  return (
    <>
      <Edit
        saveButtonProps={{
          ...saveButtonProps,
          disabled: visitorList.length === 0,
        }}
        isLoading={formLoading}
      >
        <Form
          form={formRegistration}
          {...restFormRegistration}
          layout="vertical"
          onFinish={(values) => handleUpdateVisitorRegistration(values)}
        >
          <Row gutter={[16, 24]}>
            <Col xs={24} md={12}>
              <Form.Item
                label={t("visitors_registrations.fields.name")}
                name="name"
                rules={[
                  {
                    required: true,
                    message: t("visitors_registrations.errors.nameRequired"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                label={t("visitors_registrations.fields.area")}
                name="area"
                rules={[
                  {
                    required: true,
                    message: t("visitors_registrations.errors.areaRequired"),
                  },
                ]}
              >
                <Select
                  {...restSelectAreaProps}
                  showSearch={true}
                  mode="multiple"
                  filterOption={(input, option) =>
                    fuzzySearch(
                      toLowerCaseNonAccentVietnamese(
                        String(option?.label ?? "")
                      ),
                      toLowerCaseNonAccentVietnamese(input)
                    )
                  }
                />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                label={t("visitors_registrations.fields.startTime")}
                name="startTime"
                getValueProps={(value) => ({
                  value: value ? dayjs(value) : "",
                })}
                rules={[
                  {
                    required: true,
                    message: t(
                      "visitors_registrations.errors.startTimeRequired"
                    ),
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  format={DATE_TIME_FORMAT}
                  showTime
                  disabledDate={(d) => {
                    const today = new Date();
                    return d && d.isBefore(today) && !d.isSame(today, "day");
                  }}
                />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                label={t("visitors_registrations.fields.endTime")}
                name="endTime"
                getValueProps={(value) => ({
                  value: value ? dayjs(value) : "",
                })}
                rules={[
                  {
                    required: true,
                    message: t("visitors_registrations.errors.endTimeRequired"),
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  format={DATE_TIME_FORMAT}
                  showTime
                  disabledDate={(d) => {
                    const today = new Date();
                    return d && d.isBefore(today) && !d.isSame(today, "day");
                  }}
                />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item name={VISITORS_KEY}>
                <Input hidden />
              </Form.Item>
            </Col>

            <Col xs={24}>
              <PageHeader
                backIcon={null}
                style={{ padding: 0 }}
                title={
                  <Text
                    style={{
                      fontSize: 16,
                    }}
                  >
                    {t("visitors_registrations.visitorList.titles.list")}
                  </Text>
                }
                extra={[
                  <Button
                    key="add-visitor"
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => showAddVisitor()}
                  >
                    {t("visitors_registrations.visitorList.buttons.addVisitor")}
                  </Button>,
                ]}
              />
            </Col>

            <Col xs={24}>
              <VisitorTable
                visitorList={visitorList}
                onEdit={(record) => {
                  showAddVisitor();
                  setTimeout(
                    () => formAddVisitor?.setFieldsValue({ ...record }),
                    0
                  );
                }}
                onDelete={(record) => {
                  deleteVisitor(record);
                }}
              />
            </Col>
          </Row>
        </Form>
      </Edit>

      <Modal
        {...modalProps}
        title={t("visitors_registrations.visitorList.fields.name")}
      >
        <Form
          {...restModalFormProps}
          layout="vertical"
          form={formAddVisitor}
          onFinish={() =>
            formAddVisitor
              ?.validateFields()
              .then((values) => addOrEditVisitor(values))
              .catch((error) => console.log("error", error))
          }
        >
          {visibleAddVisitor && <AddVisitorFormContent />}
        </Form>
      </Modal>
    </>
  );
};
