import {
  AntdLayout,
  Avatar,
  Button,
  Dropdown,
  Icons,
  Menu,
  Space,
} from "@pankod/refine-antd";
import { useGetLocale, useSetLocale } from "@pankod/refine-core";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import useUserRole from "hooks/useUserRole";
import { MenuPopover } from "./MenuPopover";
import { NotificationPopover } from "./NotificationPopover";

const { DownOutlined } = Icons;

export const Header: React.FC = () => {
  const { i18n } = useTranslation();
  const locale = useGetLocale();
  const changeLanguage = useSetLocale();
  const currentLocale = locale();
  const { hasSecurityRole } = useUserRole();

  const langMenu = useMemo(() => {
    // Only support select to change language if available languages are more than 1
    if (i18n.languages?.length > 1) {
      return (
        <Menu selectedKeys={currentLocale ? [currentLocale] : []}>
          {[...(i18n.languages || [])].sort().map((lang: string) => (
            <Menu.Item
              key={lang}
              onClick={() => changeLanguage(lang)}
              icon={
                <span style={{ marginRight: 8 }}>
                  <Avatar size={16} src={`/images/flags/${lang}.svg`} />
                </span>
              }
            >
              {lang === "en" ? "English" : "Tiếng Việt"}
            </Menu.Item>
          ))}
        </Menu>
      );
    }

    return null;
  }, [currentLocale, changeLanguage, i18n.languages]);

  return (
    <AntdLayout.Header
      style={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        padding: "0px 24px",
        height: "64px",
        backgroundColor: "#FFF",
      }}
    >
      <Space align="center" size="middle">
        {hasSecurityRole && <NotificationPopover />}

        {langMenu && (
          <Dropdown overlay={langMenu}>
            <Button type="link">
              <Space>
                <Avatar size={16} src={`/images/flags/${currentLocale}.svg`} />
                {currentLocale === "en" ? "English" : "Tiếng Việt"}
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        )}

        <MenuPopover />
      </Space>
    </AntdLayout.Header>
  );
};
