import { Modal } from "@pankod/refine-antd";
import usePreviewImage from "hooks/usePreviewImage";

export interface ModalPreviewImageProps {
  url?: string;
  name?: string;
}

export const ModalPreviewImage: React.FC<ModalPreviewImageProps> = ({
  url = "",
  name = "",
}) => {
  const {
    previewOpen,
    previewImage,
    previewTitle,
    handleCancel,
    handlePreview,
  } = usePreviewImage();

  return (
    <>
      <img
        alt="img-preview"
        style={{ height: 30, cursor: "pointer" }}
        src={url}
        onClick={() => handlePreview({ url, name })}
      />

      <Modal
        visible={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="img-preview" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
};
