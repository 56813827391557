import {
  Col,
  Create,
  Form,
  Input,
  Row,
  Select,
  useForm,
  useSelect,
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useTranslate } from "@pankod/refine-core";
import dayjs from "dayjs";

import DatePicker from "components/datepicker";
// import { UploadImage } from "components/upload";
import { DATE_FORMAT, GENDER_LIST } from "config";
import { IArea, IDepartment, IFloor, IStaff } from "interfaces";
import { useEffect } from "react";
import { isValidPhoneNumb } from "utils/phoneNumberHelper";
import { fuzzySearch } from "utils/searchHelper";
import { toLowerCaseNonAccentVietnamese } from "utils/vietnameseHelper";

export const StaffCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const {
    formProps: { form, ...restFormProps },
    saveButtonProps,
    formLoading,
  } = useForm<IStaff>({
    errorNotification: {
      type: "error",
      message: t("staffs.notification.createError"),
    },
    successNotification: {
      type: "success",
      message: t("staffs.notification.createSuccess"),
    },
  });

  const departmentId: string = Form.useWatch("departmentId", form);

  const {
    selectProps: {
      showSearch: showDeparmentSearch,
      onSearch: onDepartmentSearch,
      ...restSelectDepartmentProps
    },
    queryResult: departmentQueryResults,
  } = useSelect<IDepartment>({
    resource: "departments",
    optionLabel: "name",
    pagination: {
      current: 1,
      pageSize: 99999, // use the large PageSize to get full
    },
  });

  useEffect(() => {
    if (departmentId && departmentQueryResults && form) {
      const department = departmentQueryResults?.data?.data?.find(
        (department) => department.id === departmentId
      );
      form?.setFieldsValue({
        areas: department?.areas?.map((area) => area?.id) ?? [],
        floors: department?.floors?.map((floor) => floor?.id) ?? [],
      });
    }
  }, [form, departmentId, departmentQueryResults]);

  const {
    selectProps: {
      showSearch: showAreaSearch,
      onSearch: onAreaSearch,
      ...restSelectAreaProps
    },
  } = useSelect<IArea>({
    resource: "areas",
    optionLabel: "name",
    pagination: {
      current: 1,
      pageSize: 99999, // use the large PageSize to get full
    },
  });

  const {
    selectProps: {
      onSearch: onSearchFloor,
      showSearch: showSearchFloor,
      ...restSelectFloorProps
    },
  } = useSelect<IFloor>({
    resource: "floors",
    optionLabel: "name",
    hasPagination: false,
  });

  return (
    <Create saveButtonProps={saveButtonProps} isLoading={formLoading}>
      <Form form={form} {...restFormProps} layout="vertical">
        <Row gutter={[16, 24]}>
          <Col xs={24} md={12}>
            <Form.Item
              label={t("staffs.fields.name")}
              name="fullName"
              rules={[
                {
                  required: true,
                  message: t("staffs.errors.nameRequired"),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              label={t("staffs.fields.phone")}
              name="mobile"
              rules={[
                {
                  required: true,
                  message: t("staffs.errors.phoneRequired"),
                },
                {
                  validator: (_, value) => {
                    if (isValidPhoneNumb(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(t("staffs.errors.phoneInvalid"))
                    );
                  },
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              label={t("staffs.fields.department")}
              name={"departmentId"}
              rules={[
                {
                  required: true,
                  message: t("staffs.errors.departmentRequired"),
                },
              ]}
            >
              <Select
                {...restSelectDepartmentProps}
                showSearch={true}
                filterOption={(input, option) =>
                  fuzzySearch(
                    toLowerCaseNonAccentVietnamese(String(option?.label ?? "")),
                    toLowerCaseNonAccentVietnamese(input)
                  )
                }
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              label={t("staffs.fields.allowed_areas")}
              name="areas"
              rules={[
                {
                  required: true,
                  message: t("staffs.errors.allowedAreasRequired"),
                },
              ]}
            >
              <Select
                {...restSelectAreaProps}
                showSearch={true}
                mode="multiple"
                filterOption={(input, option) =>
                  fuzzySearch(
                    toLowerCaseNonAccentVietnamese(String(option?.label ?? "")),
                    toLowerCaseNonAccentVietnamese(input)
                  )
                }
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              label={t("staffs.fields.allowed_floors")}
              name={"floors"}
              rules={[
                {
                  required: true,
                  message: t("staffs.errors.allowedFloorsRequired"),
                },
              ]}
            >
              <Select
                {...restSelectFloorProps}
                showSearch={true}
                mode="multiple"
                filterOption={(input, option) =>
                  fuzzySearch(
                    toLowerCaseNonAccentVietnamese(String(option?.label ?? "")),
                    toLowerCaseNonAccentVietnamese(input)
                  )
                }
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              label={t("staffs.fields.citizenIdentityNo")}
              name="citizenIdentityNo"
              rules={[
                {
                  required: true,
                  message: t("staffs.errors.citizenIdentityNoRequired"),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item label={t("staffs.fields.title")} name="title">
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              label={t("staffs.fields.email")}
              name="email"
              rules={[
                {
                  type: "email",
                  message: t("staffs.errors.validEmail"),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item label={t("staffs.fields.address")} name="address">
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item label={t("staffs.fields.gender")} name="gender">
              <Select options={GENDER_LIST(t)} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              label={t("staffs.fields.dob")}
              name="dob"
              getValueProps={(value) => ({
                value: value ? dayjs(value) : "",
              })}
            >
              <DatePicker format={DATE_FORMAT} style={{ width: "100%" }} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} />

          <Col xs={24} md={12}>
            {/* <Form.Item
              label={t("staffs.fields.image")}
              name="image"
              getValueFromEvent={(e) => {
                const file = Array.isArray(e) ? e[0] : e?.fileList?.[0];
                return file?.response?.url;
              }}
            >
              <UploadImage />
            </Form.Item> */}
          </Col>

          <Col xs={24} md={12} />

          {/* <Col xs={24}>
            <Form.Item
              label={t("staffs.fields.description")}
              name="description"
            >
              <Input.TextArea rows={6} />
            </Form.Item>
          </Col> */}
        </Row>
      </Form>
    </Create>
  );
};
