import {
  Button,
  Col,
  Form,
  Icons,
  Modal,
  Row,
  Select,
  notification,
  useModal,
  useSelect,
} from "@pankod/refine-antd";
import { useApiUrl } from "@pankod/refine-core";
import dayjs from "dayjs";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import axiosInstance from "axiosInstance";
import DatePicker from "components/datepicker";
import { DATE_FORMAT, DEFAULT_COUNT_DATE_EXPORT } from "config";
import { IDepartment } from "interfaces";
import { downloadData } from "utils/commonHelper";
import { fuzzySearch } from "utils/searchHelper";
import { toLowerCaseNonAccentVietnamese } from "utils/vietnameseHelper";

const { ExportOutlined } = Icons;

export const AttendanceExport: React.FC = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const from = Form.useWatch("from", form);
  const to = Form.useWatch("to", form);

  const apiUrl = useApiUrl();
  const [isRefetchingExport, setIsRefetchingExport] = useState(false);

  const handleExportAttendance = async (values: any) => {
    const { from, to, department } = values;
    if (!from || !to) return;

    try {
      setIsRefetchingExport(true);
      const { data } = await axiosInstance.get(`${apiUrl}/staffs/getAT`, {
        responseType: "blob",
        params: {
          from: dayjs(from).startOf("days").toISOString(),
          to: dayjs(to).endOf("days").toISOString(),
          department: department,
        },
      });
      downloadData(data, t("staffs.attendance_exports.filename"));
      notification.success({
        message: t("staffs.attendance_exports.notification.exportSuccess"),
      });
      close();
    } catch (error: any) {
      notification.error({
        message:
          error?.message ??
          t("staffs.attendance_exports.notification.exportError"),
      });
    } finally {
      setIsRefetchingExport(false);
    }
  };

  const { modalProps, show, close } = useModal({
    modalProps: {
      title: t("staffs.attendance_exports.title"),
      okText: t("buttons.ok"),
      cancelText: t("buttons.cancel"),
      afterClose() {
        form?.resetFields();
      },
      okButtonProps: {
        loading: isRefetchingExport,
      },
      onOk: () => {
        form
          .validateFields()
          .then(handleExportAttendance)
          .catch((error) => console.log("error", error));
      },
      onCancel: () => {
        form?.resetFields();
      },
    },
  });

  const {
    selectProps: {
      showSearch: showSearchDepartment,
      onSearch: onSearchDepartment,
      ...restSelectDepartmentProps
    },
  } = useSelect<IDepartment>({
    resource: "departments",
    optionLabel: "name",
    pagination: {
      current: 1,
      pageSize: 99999, // use the large PageSize to get full
    },
  });

  return (
    <>
      <Button icon={<ExportOutlined />} onClick={() => show()}>
        {t("staffs.attendance_exports.button")}
      </Button>

      <Modal {...modalProps}>
        <Form layout="vertical" form={form}>
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <Form.Item
                label={t("staffs.attendance_exports.fields.from")}
                name={["from"]}
                rules={[
                  {
                    required: true,
                    message: t("staffs.attendance_exports.errors.fromRequired"),
                  },
                ]}
              >
                <DatePicker
                  format={DATE_FORMAT}
                  style={{ width: "100%" }}
                  disabled={isRefetchingExport}
                  disabledDate={(date) =>
                    date > dayjs(new Date()).endOf("days") ||
                    (to && date > to) ||
                    (to &&
                      Math.abs(date!.diff(to, "day")) >
                        DEFAULT_COUNT_DATE_EXPORT)
                  }
                />
              </Form.Item>
            </Col>

            <Col xs={24}>
              <Form.Item
                label={t("staffs.attendance_exports.fields.to")}
                name={["to"]}
                rules={[
                  {
                    required: true,
                    message: t("staffs.attendance_exports.errors.toRequired"),
                  },
                ]}
              >
                <DatePicker
                  format={DATE_FORMAT}
                  style={{ width: "100%" }}
                  disabled={isRefetchingExport}
                  disabledDate={(date) =>
                    date > dayjs(new Date()).endOf("days") ||
                    (from && date < from) ||
                    (from &&
                      Math.abs(date!.diff(from, "day")) >
                        DEFAULT_COUNT_DATE_EXPORT)
                  }
                />
              </Form.Item>
            </Col>

            <Col xs={24}>
              <Form.Item
                label={t("staffs.attendance_exports.fields.department")}
                name={"department"}
                // rules={[
                //   {
                //     required: true,
                //     message: t(
                //       "staffs.attendance_exports.errors.departmentRequired"
                //     ),
                //   },
                // ]}
              >
                <Select
                  {...restSelectDepartmentProps}
                  disabled={isRefetchingExport}
                  showSearch={true}
                  allowClear={true}
                  filterOption={(input, option) =>
                    fuzzySearch(
                      toLowerCaseNonAccentVietnamese(
                        String(option?.label ?? "")
                      ),
                      toLowerCaseNonAccentVietnamese(input)
                    )
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
