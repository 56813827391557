import {
  AuthProps,
  AntdLayout,
  Row,
  Col,
  Card,
  Form,
  Typography,
  Input,
  Button,
} from "@pankod/refine-antd";
import {
  useApiUrl,
  useCustomMutation,
  useTranslate,
  useRouterContext,
} from "@pankod/refine-core";
import { PATHS } from "config";
import { IForgotPasswordTypes } from "interfaces";

const { Title, Text } = Typography;

export const ForgotPasswordPage: React.FC<AuthProps> = () => {
  const t = useTranslate();

  const [form] = Form.useForm();

  const { Link } = useRouterContext();

  const apiUrl = useApiUrl();

  const { mutate: forgotPassword, isLoading } =
    useCustomMutation<IForgotPasswordTypes>();

  const handleForgotPassword = async ({ email }: IForgotPasswordTypes) => {
    forgotPassword({
      url: `${apiUrl}/auth/forgot-password`,
      method: "post",
      values: {
        email,
      },
      errorNotification: {
        type: "error",
        message: t("pages.forgotPassword.notification.forgot_password_error"),
      },
      successNotification: {
        type: "success",
        message: t("pages.forgotPassword.notification.forgot_password_success"),
      },
    });
  };

  const CardTitle = (
    <Title
      level={3}
      style={{
        textAlign: "center",
        color: "#626262",
        fontSize: "24px",
        letterSpacing: "-0.04em",
        overflowWrap: "break-word",
        hyphens: "manual",
        textOverflow: "unset",
        whiteSpace: "pre-wrap",
      }}
    >
      {t("pages.forgotPassword.title")}
    </Title>
  );

  const CardContent = (
    <Card
      title={CardTitle}
      headStyle={{ borderBottom: 0 }}
      style={{
        maxWidth: "408px",
        margin: "auto",
      }}
    >
      <Form<IForgotPasswordTypes>
        layout="vertical"
        form={form}
        onFinish={(values) => handleForgotPassword(values)}
        requiredMark={false}
      >
        <Form.Item
          name="email"
          label={t("pages.forgotPassword.fields.email")}
          rules={[
            {
              required: true,
              message: t("pages.forgotPassword.errors.emailRequired"),
            },
            {
              type: "email",
              message: t("pages.forgotPassword.errors.validEmail"),
            },
          ]}
        >
          <Input
            type="email"
            size="large"
            placeholder={t("pages.forgotPassword.fields.email")}
          />
        </Form.Item>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "12px",
          }}
        >
          <Text
            style={{
              fontSize: 12,
              marginLeft: "auto",
            }}
          >
            {t("pages.forgotPassword.buttons.haveAccount")}{" "}
            <Link
              style={{
                fontWeight: "bold",
              }}
              to={PATHS.login}
            >
              {t("pages.login.signin")}
            </Link>
          </Text>
        </div>
        <Form.Item>
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            loading={isLoading}
            block
          >
            {t("pages.forgotPassword.buttons.submit")}
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );

  return (
    <AntdLayout
      style={{
        background: "#172B4D",
      }}
    >
      <Row
        justify="center"
        align="middle"
        style={{
          height: "100vh",
        }}
      >
        <Col xs={22}>{CardContent}</Col>
      </Row>
    </AntdLayout>
  );
};
