import { Col, Row, Show } from "@pankod/refine-antd";
import {
  IResourceComponentsProps,
  useShow,
  useTranslate,
} from "@pankod/refine-core";
import TextBox from "components/textBox";

import { IArea } from "interfaces";
import { AreaDeviceList } from "./private/deviceList";

export const AreaShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { queryResult } = useShow<IArea>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <>
      <Show isLoading={isLoading}>
        <Row gutter={[16, 24]}>
          <Col xs={24} md={12}>
            <TextBox label={t("areas.fields.name")} value={record?.name} />
          </Col>

          <Col xs={24} md={12}>
            <TextBox label={t("areas.fields.code")} value={record?.code} />
          </Col>

          <Col xs={24}>
            <TextBox
              label={t("areas.fields.description")}
              value={record?.description || ""}
              valueStyles={{
                minHeight: 88,
              }}
            />
          </Col>
        </Row>

        {record?.id && <AreaDeviceList area={record} />}
      </Show>
    </>
  );
};
