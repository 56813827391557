import {
  Button,
  Col,
  Edit,
  Form,
  Icons,
  Input,
  PageHeader,
  Row,
  Select,
  Space,
  Switch,
  Typography,
  useForm,
  useSelect,
} from "@pankod/refine-antd";
import { useApiUrl, useCustomMutation } from "@pankod/refine-core";
import {
  IResourceComponentsProps,
  useNavigation,
  useTranslate,
} from "@pankod/refine-core";
import dayjs from "dayjs";

import DatePicker from "components/datepicker";
import {
  ButtonRegisterCard,
  ButtonRegisterFace,
  ButtonRegisterFinger,
} from "components/registers";
import { DATE_FORMAT, GENDER_LIST } from "config";
import { IArea, IDepartment, IFloor, IStaff } from "interfaces";
import { useEffect } from "react";
import { isValidPhoneNumb } from "utils/phoneNumberHelper";
import { fuzzySearch } from "utils/searchHelper";
import { toLowerCaseNonAccentVietnamese } from "utils/vietnameseHelper";
import { sortFloors } from "utils/commonHelper";

const { Text } = Typography;

const { EyeOutlined } = Icons;

export const StaffEdit: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { show, list } = useNavigation();

  const {
    formProps: { form, ...restFormProps },
    saveButtonProps,
    formLoading,
    queryResult,
  } = useForm<IStaff>({
    errorNotification: {
      type: "error",
      message: t("staffs.notification.editError"),
    },
    successNotification: {
      type: "success",
      message: t("staffs.notification.editSuccess"),
    },
  });

  const {
    selectProps: {
      showSearch: showDeparmentSearch,
      onSearch: onDepartmentSearch,
      ...restSelectDepartmentProps
    },
  } = useSelect<IDepartment>({
    resource: "departments",
    optionLabel: "name",
    pagination: {
      current: 1,
      pageSize: 99999, // use the large PageSize to get full
    },
  });

  useEffect(() => {
    if (queryResult && form) {
      form?.setFieldsValue({
        areas: queryResult?.data?.data.userAccess?.areas?.map(
          (area) => area?.id
        ),
        floors: sortFloors(
          queryResult?.data?.data.userAccess?.floors ?? []
        ).map((floor) => floor?.id),
      });
    }
  }, [form, queryResult]);

  const {
    selectProps: {
      showSearch: showAreaSearch,
      onSearch: onAreaSearch,
      ...restSelectAreaProps
    },
  } = useSelect<IArea>({
    resource: "areas",
    optionLabel: "name",
    pagination: {
      current: 1,
      pageSize: 99999, // use the large PageSize to get full
    },
  });

  const {
    selectProps: {
      onSearch: onSearchFloor,
      showSearch: showSearchFloor,
      ...restSelectFloorProps
    },
  } = useSelect<IFloor>({
    resource: "floors",
    optionLabel: "name",
    hasPagination: false,
  });

  const apiUrl = useApiUrl();

  const { mutate: updateStaff } = useCustomMutation<IStaff>();

  const handleUpdateStaff = async (props: IStaff) => {
    updateStaff(
      {
        url: `${apiUrl}/staffs/update`,
        method: "put",
        values: {
          ...props,
          id: queryResult?.data?.data?.id,
          areas: props.areas?.map((area) => ({
            id: area,
          })),
          floors: props.floors?.map((floor) => floor),
        },
        errorNotification: {
          type: "error",
          message: t("staffs.notification.editError"),
        },
        successNotification: {
          type: "success",
          message: t("staffs.notification.editSuccess"),
        },
      },
      {
        onError: (error, variables, context) => {
          console.log("handleUpdateStaff", { error, variables, context });
        },
        onSuccess: (data, variables, context) => {
          console.log("handleUpdateStaff", { data, variables, context });
          list("staffs");
        },
      }
    );
  };

  return (
    <Edit
      saveButtonProps={saveButtonProps}
      isLoading={formLoading}
      headerButtons={({ defaultButtons }) => (
        <>
          {defaultButtons}
          <Button
            icon={<EyeOutlined />}
            onClick={() => {
              if (queryResult?.data?.data.id) {
                show("staffs", queryResult?.data?.data.id);
              } else {
                console.log("something went wrong", queryResult);
              }
            }}
          >
            {t("buttons.show")}
          </Button>
        </>
      )}
      footerButtons={({ defaultButtons }) => (
        <>
          <Space wrap>
            <ButtonRegisterCard
              userAccess={queryResult?.data?.data.userAccess}
              username={queryResult?.data?.data.fullName}
            />
            <ButtonRegisterFace
              userAccess={queryResult?.data?.data.userAccess}
              username={queryResult?.data?.data.fullName}
            />
            <ButtonRegisterFinger
              userAccess={queryResult?.data?.data.userAccess}
              username={queryResult?.data?.data.fullName}
            />
          </Space>
          <Space>{defaultButtons}</Space>
        </>
      )}
      footerButtonProps={{
        style: {
          display: "flex",
          justifyContent: "space-between",
          cursor: "default",
        },
      }}
    >
      <Form
        form={form}
        {...restFormProps}
        layout="vertical"
        onFinish={(values) => handleUpdateStaff(values)}
      >
        <Row gutter={[16, 24]}>
          <Col xs={24}>
            <PageHeader
              backIcon={null}
              style={{ padding: 0 }}
              title={
                <Text style={{ fontSize: 16 }}>{t("staffs.basic_header")}</Text>
              }
            />
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              label={t("staffs.fields.name")}
              name="fullName"
              rules={[
                {
                  required: true,
                  message: t("staffs.errors.nameRequired"),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              label={t("staffs.fields.phone")}
              name="mobile"
              rules={[
                {
                  required: true,
                  message: t("staffs.errors.phoneRequired"),
                },
                {
                  validator: (_, value) => {
                    if (isValidPhoneNumb(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(t("staffs.errors.phoneInvalid"))
                    );
                  },
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              label={t("staffs.fields.department")}
              name={["department", "id"]}
              rules={[
                {
                  required: true,
                  message: t("staffs.errors.departmentRequired"),
                },
              ]}
            >
              <Select
                {...restSelectDepartmentProps}
                showSearch={true}
                filterOption={(input, option) =>
                  fuzzySearch(
                    toLowerCaseNonAccentVietnamese(String(option?.label ?? "")),
                    toLowerCaseNonAccentVietnamese(input)
                  )
                }
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              label={t("staffs.fields.allowed_areas")}
              name="areas"
              rules={[
                {
                  required: true,
                  message: t("staffs.errors.allowedAreasRequired"),
                },
              ]}
            >
              <Select
                {...restSelectAreaProps}
                showSearch={true}
                mode="multiple"
                filterOption={(input, option) =>
                  fuzzySearch(
                    toLowerCaseNonAccentVietnamese(String(option?.label ?? "")),
                    toLowerCaseNonAccentVietnamese(input)
                  )
                }
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              label={t("staffs.fields.allowed_floors")}
              name={"floors"}
              rules={[
                {
                  required: true,
                  message: t("staffs.errors.allowedFloorsRequired"),
                },
              ]}
            >
              <Select
                {...restSelectFloorProps}
                showSearch={true}
                mode="multiple"
                filterOption={(input, option) =>
                  fuzzySearch(
                    toLowerCaseNonAccentVietnamese(String(option?.label ?? "")),
                    toLowerCaseNonAccentVietnamese(input)
                  )
                }
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              label={t("staffs.fields.citizenIdentityNo")}
              name="citizenIdentityNo"
              rules={[
                {
                  required: true,
                  message: t("staffs.errors.citizenIdentityNoRequired"),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item label={t("staffs.fields.title")} name="title">
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              label={t("staffs.fields.email")}
              name="email"
              rules={[
                {
                  type: "email",
                  message: t("staffs.errors.validEmail"),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item label={t("staffs.fields.address")} name="address">
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item label={t("staffs.fields.gender")} name="gender">
              <Select options={GENDER_LIST(t)} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              label={t("staffs.fields.dob")}
              name="dob"
              getValueProps={(value) => ({
                value: value ? dayjs(value) : "",
              })}
            >
              <DatePicker format={DATE_FORMAT} style={{ width: "100%" }} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <></>
          </Col>

          <Col xs={24}>
            <PageHeader
              backIcon={null}
              style={{ padding: "20px 0px 0px" }}
              title={
                <Text style={{ fontSize: 16 }}>
                  {t("staffs.fields.userAccess.header")}
                </Text>
              }
            />
          </Col>

          <Col xs={24} md={8}>
            <Form.Item
              label={t("staffs.fields.userAccess.card")}
              name={["userAccess", "enableCardAuthentication"]}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>

          <Col xs={24} md={8}>
            <Form.Item
              label={t("staffs.fields.userAccess.face")}
              name={["userAccess", "enableFaceAuthentication"]}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>

          <Col xs={24} md={8}>
            <Form.Item
              label={t("staffs.fields.userAccess.fingerprint")}
              name={["userAccess", "enableFingerAuthentication"]}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>

          {/* <Col xs={24}>
            <Form.Item
              label={t("staffs.fields.description")}
              name="description"
            >
              <Input.TextArea rows={6} />
            </Form.Item>
          </Col> */}
        </Row>
      </Form>
    </Edit>
  );
};
