import { UploadFile } from "@pankod/refine-antd";
import { toArray } from "./commonHelper";

export const getFileUrlsFromEvent = (e: any): string[] => {
  const files = Array.isArray(e) ? e : e?.fileList;
  const fileUrls = files.map((file: any) => file?.response?.url);
  if (!fileUrls || fileUrls.length < 1) {
    return [];
  }
  return fileUrls;
};

export const getFileValueProps = (
  value: any,
  names: string[]
): UploadFile[] => {
  return toArray(value)
    .filter((value) => Boolean(value))
    .map((url, index) => ({
      uid: `uid-${index}`,
      name: names[index],
      status: "done",
      url,
    }));
};
