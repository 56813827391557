import {
  Col,
  Create,
  Form,
  Input,
  Row,
  Select,
  useForm,
  useSelect,
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useTranslate } from "@pankod/refine-core";

import { IArea, IDepartment, IFloor } from "interfaces";
import { fuzzySearch } from "utils/searchHelper";
import { toLowerCaseNonAccentVietnamese } from "utils/vietnameseHelper";

export const DepartmentCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { formProps, saveButtonProps, formLoading } = useForm<IDepartment>({
    errorNotification: {
      type: "error",
      message: t("departments.notification.createError"),
    },
    successNotification: {
      type: "success",
      message: t("departments.notification.createSuccess"),
    },
  });

  const {
    selectProps: {
      onSearch: onSearchArea,
      showSearch: showSearchArea,
      ...restSelectAreaProps
    },
  } = useSelect<IArea>({
    resource: "areas",
    optionLabel: "name",
    pagination: {
      current: 1,
      pageSize: 99999, // use the large PageSize to get full
    },
  });

  const {
    selectProps: {
      onSearch: onSearchFloor,
      showSearch: showSearchFloor,
      ...restSelectFloorProps
    },
  } = useSelect<IFloor>({
    resource: "floors",
    optionLabel: "name",
    hasPagination: false
  });

  return (
    <Create saveButtonProps={saveButtonProps} isLoading={formLoading}>
      <Form {...formProps} layout="vertical">
        <Row gutter={[16, 24]}>
          <Col xs={24} md={12}>
            <Form.Item
              label={t("departments.fields.name")}
              name="name"
              rules={[
                {
                  required: true,
                  message: t("departments.errors.nameRequired"),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              label={t("departments.fields.code")}
              name="code"
              rules={[
                {
                  required: true,
                  message: t("departments.errors.codeRequired"),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              label={t("departments.fields.area")}
              name={"areas"}
              rules={[
                {
                  required: true,
                  message: t("departments.errors.areaRequired"),
                },
              ]}
            >
              <Select
                {...restSelectAreaProps}
                showSearch={true}
                mode="multiple"
                filterOption={(input, option) =>
                  fuzzySearch(
                    toLowerCaseNonAccentVietnamese(String(option?.label ?? "")),
                    toLowerCaseNonAccentVietnamese(input)
                  )
                }
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              label={t("departments.fields.floor")}
              name={"floors"}
              rules={[
                {
                  required: true,
                  message: t("departments.errors.floorRequired"),
                },
              ]}
            >
              <Select
                {...restSelectFloorProps}
                showSearch={true}
                mode="multiple"
                filterOption={(input, option) =>
                  fuzzySearch(
                    toLowerCaseNonAccentVietnamese(String(option?.label ?? "")),
                    toLowerCaseNonAccentVietnamese(input)
                  )
                }
              />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item
              label={t("departments.fields.description")}
              name="description"
            >
              <Input.TextArea rows={6} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Create>
  );
};
