import React from "react";
import { TitleProps as RefineTitleProps } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";

const { Link } = routerProvider;

interface TitleProps extends RefineTitleProps {
  imgWidth?: number;
  imgCollapsedWidth?: number;
}

export const Title: React.FC<TitleProps> = ({
  collapsed,
  imgWidth = 200,
  imgCollapsedWidth = 110,
}) => {
  const to = window.location.pathname.includes("login")
    ? `${window.location.pathname}${window.location.search}`
    : "/";

  return (
    <Link
      to={to}
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: window.location.pathname.includes("login")
          ? "default"
          : "pointer",
      }}
    >
      {collapsed ? (
        <img
          src={"/logo-collapsed.png"}
          alt="JMT"
          style={{
            width: imgCollapsedWidth,
            padding: "12px 24px",
          }}
        />
      ) : (
        <img
          src={"/logo.png"}
          alt="JMT"
          style={{
            width: imgWidth,
            padding: "12px 24px",
          }}
        />
      )}
    </Link>
  );
};
