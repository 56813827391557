import {
  Button,
  Checkbox,
  Col,
  Form,
  Icons,
  Modal,
  Row,
  Typography,
  useModal,
} from "@pankod/refine-antd";
import { useApiUrl, useCustomMutation } from "@pankod/refine-core";
import { useTranslation } from "react-i18next";

import { IDevice, IResetDeviceTypes } from "interfaces";
import TextBox from "components/textBox";

const { ClearOutlined } = Icons;

interface ResetDeviceProps {
  data: IDevice;
}

const ResetDevice: React.FC<ResetDeviceProps> = ({ data }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const resetNetwork = Form.useWatch("resetNetwork", form);
  const resetUsers = Form.useWatch("resetUsers", form);
  const resetDB = Form.useWatch("resetDB", form);

  const apiUrl = useApiUrl();
  const { mutate: resetDevice, isLoading } =
    useCustomMutation<IResetDeviceTypes>();

  const handleResetDevice = async ({
    resetNetwork,
    resetDB,
    resetUsers,
  }: IResetDeviceTypes) => {
    if (!data?.code) return;

    resetDevice(
      {
        url: `${apiUrl}/devices/${data?.code}/reset-config`,
        method: "post",
        values: {
          resetNetwork,
          resetDB,
          resetUsers,
        },
        errorNotification(error: any) {
          return {
            type: "error",
            message:
              error?.message ??
              t("devices.reset_device.notification.resetError"),
          };
        },
        successNotification: {
          type: "success",
          message: t("devices.reset_device.notification.resetSuccess"),
        },
      },
      {
        onSuccess() {
          close();
        },
      }
    );
  };

  const { modalProps, show, close } = useModal({
    modalProps: {
      title: t("devices.reset_device.title"),
      okText: t("buttons.ok"),
      cancelText: t("buttons.cancel"),
      afterClose() {
        form?.resetFields();
      },
      okButtonProps: {
        loading: isLoading,
        disabled: !resetNetwork && !resetUsers && !resetDB,
      },
      onOk: () => {
        form
          .validateFields()
          .then((values) => handleResetDevice(values))
          .catch((error) => console.log("error", error));
      },
      onCancel: () => {
        form?.resetFields();
      },
    },
  });

  return (
    <>
      <Button icon={<ClearOutlined />} size="small" onClick={() => show()} />

      <Modal {...modalProps}>
        <Row gutter={[16, 24]}>
          <Col xs={24}>
            <Typography.Title level={5} style={{ margin: 0 }}>
              {t("devices.reset_device.common")}
            </Typography.Title>
          </Col>
          <Col xs={12}>
            <TextBox label={t("devices.fields.name")} value={data?.name} />
          </Col>
          <Col xs={12}>
            <TextBox label={t("devices.fields.code")} value={data?.code} />
          </Col>

          <Col xs={24}>
            <Typography.Title level={5} style={{ margin: 0 }}>
              {t("devices.reset_device.data_to_reset")}
            </Typography.Title>
          </Col>
          <Col xs={24}>
            <Form layout="vertical" form={form}>
              <Form.Item
                name="resetNetwork"
                valuePropName="checked"
                style={{ marginBottom: 0 }}
              >
                <Checkbox>{t("devices.reset_device.fields.network")}</Checkbox>
              </Form.Item>
              <Form.Item
                name="resetUsers"
                valuePropName="checked"
                style={{ marginBottom: 0 }}
              >
                <Checkbox>{t("devices.reset_device.fields.users")}</Checkbox>
              </Form.Item>
              <Form.Item
                name="resetDB"
                valuePropName="checked"
                style={{ marginBottom: 0 }}
              >
                <Checkbox>{t("devices.reset_device.fields.db")}</Checkbox>
              </Form.Item>
            </Form>
          </Col>

          <Typography.Text style={{ margin: 0, color: "#FF4D4F" }}>
            {t("devices.reset_device.data_to_reset_des")}
          </Typography.Text>
        </Row>
      </Modal>
    </>
  );
};

export default ResetDevice;
