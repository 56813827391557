import { Spin } from "@pankod/refine-antd";

export default function LoadingScreen() {
  return (
    <div
      style={{
        right: 0,
        bottom: 0,
        zIndex: 99999,
        width: "100%",
        height: "100%",
        position: "fixed",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Spin />
    </div>
  );
}
