import { Col, Edit, Form, Input, Row, useForm } from "@pankod/refine-antd";
import { IResourceComponentsProps, useTranslate } from "@pankod/refine-core";

import { IArea } from "interfaces";

export const AreaEdit: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { formProps, saveButtonProps, formLoading } = useForm<IArea>({
    errorNotification: {
      type: "error",
      message: t("areas.notification.editError"),
    },
    successNotification: {
      type: "success",
      message: t("areas.notification.editSuccess"),
    },
  });

  return (
    <Edit saveButtonProps={saveButtonProps} isLoading={formLoading}>
      <Form {...formProps} layout="vertical">
        <Row gutter={[16, 24]}>
          <Col xs={24} md={12}>
            <Form.Item
              label={t("areas.fields.name")}
              name="name"
              rules={[
                {
                  required: true,
                  message: t("areas.errors.nameRequired"),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              label={t("areas.fields.code")}
              name="code"
              rules={[
                {
                  required: true,
                  message: t("areas.errors.codeRequired"),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item label={t("areas.fields.description")} name="description">
              <Input.TextArea rows={6} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};
