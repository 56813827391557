export function randomInt(min: number, max: number) {
  return Math.floor(min + Math.random() * (max - min));
}

export function isOnlyDigit(value: string): boolean {
  return /^([0-9])+$/.test(value);
}

export function castToInt(value: string | number): number {
  if (typeof value === 'number') return value;
  return parseInt(value)
}