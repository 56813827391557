import {
  Button,
  Col,
  Edit,
  Form,
  Input,
  Modal,
  Row,
  Select,
  useForm,
  useModalForm,
  useSelect,
} from "@pankod/refine-antd";
import {
  IResourceComponentsProps,
  useApiUrl,
  useCustomMutation,
  useTranslate,
} from "@pankod/refine-core";

import { PASSWORD_PLACEHOLDER, ROLE_LIST } from "config";
import { IDepartment, IUser } from "interfaces";
import { fuzzySearch } from "utils/searchHelper";
import { toLowerCaseNonAccentVietnamese } from "utils/vietnameseHelper";

export const UserEdit: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { formProps, saveButtonProps, formLoading, queryResult } =
    useForm<IUser>({
      errorNotification: {
        type: "error",
        message: t("users.notification.editError"),
      },
      successNotification: {
        type: "success",
        message: t("users.notification.editSuccess"),
      },
    });

  const {
    selectProps: { showSearch, onSearch, ...restSelectProps },
  } = useSelect<IDepartment>({
    resource: "departments",
    optionLabel: "name",
    pagination: {
      current: 1,
      pageSize: 99999, // use the large PageSize to get full
    },
  });

  const {
    modalProps,
    formProps: { onFinish: _, form: modalForm, ...restModalFormProps },
    show: showChangePassword,
    close: closeChangePassword,
  } = useModalForm({
    action: "edit",
  });

  const { mutate: updatePassword } = useCustomMutation<IUser>();

  const apiUrl = useApiUrl();

  const handleChangePassword = async ({ password }: IUser) => {
    updatePassword(
      {
        url: `${apiUrl}/users/change_pass`,
        method: "post",
        values: {
          userId: queryResult?.data?.data.id,
          newPass: password,
        },
        errorNotification: {
          type: "error",
          message: t("users.notification.changePassError"),
        },
        successNotification: {
          type: "success",
          message: t("users.notification.changePassSuccess"),
        },
      },
      {
        onSuccess() {
          closeChangePassword();
        },
      }
    );
  };

  return (
    <>
      <Edit
        saveButtonProps={saveButtonProps}
        isLoading={formLoading}
        footerButtons={({ defaultButtons }) => (
          <>
            <Button type="link" onClick={() => showChangePassword()}>
              {t("users.buttons.changePassword")}
            </Button>
            {defaultButtons}
          </>
        )}
      >
        <Form {...formProps} layout="vertical">
          <Row gutter={[16, 24]}>
            <Col xs={24} md={12}>
              <Form.Item
                label={t("users.fields.role")}
                name={["role", "code"]}
                rules={[
                  {
                    required: true,
                    message: t("users.errors.roleRequired"),
                  },
                ]}
                initialValue={queryResult?.data?.data.roles?.[0].code}
              >
                <Select disabled options={ROLE_LIST(t)} />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                label={t("users.fields.username")}
                name="username"
                rules={[
                  {
                    required: true,
                    message: t("users.errors.usernameRequired"),
                  },
                ]}
              >
                <Input autoComplete="username" />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                label={t("users.fields.department")}
                name={["department", "id"]}
                rules={[
                  {
                    required: true,
                    message: t("users.errors.departmentRequired"),
                  },
                ]}
              >
                <Select
                  disabled
                  {...restSelectProps}
                  showSearch={true}
                  filterOption={(input, option) =>
                    fuzzySearch(
                      toLowerCaseNonAccentVietnamese(
                        String(option?.label ?? "")
                      ),
                      toLowerCaseNonAccentVietnamese(input)
                    )
                  }
                />
              </Form.Item>
            </Col>

            <Col xs={24} md={12} />
          </Row>
        </Form>
      </Edit>

      <Modal {...modalProps}>
        <Form
          {...restModalFormProps}
          layout="vertical"
          form={modalForm}
          onFinish={() =>
            modalForm
              ?.validateFields()
              .then((values) => handleChangePassword(values))
              .catch((error) => console.log("error", error))
          }
        >
          <Row gutter={[16, 24]}>
            <Col xs={24}>
              <Form.Item
                label={t("users.fields.password")}
                name="password"
                rules={[
                  {
                    required: true,
                    message: t("users.errors.passwordRequired"),
                  },
                ]}
              >
                <Input.Password
                  placeholder={PASSWORD_PLACEHOLDER}
                  autoComplete="new-password"
                />
              </Form.Item>
            </Col>

            <Col xs={24}>
              <Form.Item
                label={t("users.fields.confirmPassword")}
                name="confirmPassword"
                rules={[
                  {
                    required: true,
                    message: t("users.errors.confirmPasswordRequired"),
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(t("users.errors.confirmPasswordNotMatch"))
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  placeholder={PASSWORD_PLACEHOLDER}
                  autoComplete="new-password"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
