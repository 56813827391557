import {
  CreateButton,
  DeleteButton,
  EditButton,
  Icons,
  List,
  ShowButton,
  Space,
  Table,
  useTable,
} from "@pankod/refine-antd";
import {
  CrudFilters,
  HttpError,
  IResourceComponentsProps,
  useTranslate,
} from "@pankod/refine-core";

import { IArea, IDepartment, IFloor } from "interfaces";

const DEFAULT_SHOW_AREAS = 4;
const DEFAULT_SHOW_FLOORS = 4;

const {
  // SearchOutlined,
  PlusOutlined,
} = Icons;

export const DepartmentList: React.FC<IResourceComponentsProps> = ({
  canCreate,
}) => {
  const t = useTranslate();

  const {
    tableProps,
    // searchFormProps
  } = useTable<IDepartment, HttpError, { q: string }>({
    onSearch: ({ q }) => {
      const filters: CrudFilters = [];

      filters.push({
        field: "q",
        operator: "eq",
        value: q,
      });

      return filters;
    },
  });

  // const { form, ...restFormProps } = searchFormProps;

  // const { run: searchDepartment } = useDebounceFn(() => form?.submit(), {
  //   wait: 300,
  // });

  // const { mutate: updateStatus, isLoading: isUpdating } =
  // useUpdate<IDepartment>();

  return (
    <List
      headerButtons={
        canCreate ? (
          <CreateButton type="primary" icon={<PlusOutlined />}>
            {t("buttons.create_new")}
          </CreateButton>
        ) : null
      }
    >
      {/* <Row gutter={16}>
        <Col flex="auto">
          <Form layout="vertical" {...restFormProps} form={form}>
            <Form.Item name="q">
              <Input
                style={{ maxWidth: 500 }}
                placeholder={t("placeholders.search")}
                prefix={<SearchOutlined />}
                onChange={() => searchDepartment()}
              />
            </Form.Item>
          </Form>
        </Col>
        <Col>
          {canCreate && (
            <CreateButton type="primary" icon={<PlusOutlined />}>
              {t("buttons.create_new")}
            </CreateButton>
          )}
        </Col>
      </Row> */}

      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="idx" key="idx" title={"#"} />

        <Table.Column
          dataIndex="name"
          key="name"
          title={t("departments.fields.name")}
        />

        <Table.Column
          dataIndex="code"
          key="code"
          title={t("departments.fields.code")}
        />

        <Table.Column
          dataIndex={"areas"}
          key="areas"
          title={t("departments.fields.area")}
          render={(_areas: IArea[]) => {
            const areas = _areas ?? [];
            const showAreas = areas.slice(0, DEFAULT_SHOW_AREAS);
            const remainArea =
              areas.length > DEFAULT_SHOW_AREAS
                ? areas.length - DEFAULT_SHOW_AREAS
                : 0;
            return `${showAreas?.map((area) => area.name).join(", ")} ${
              remainArea > 0 ? `(+${remainArea})` : ""
            }`;
          }}
        />

        <Table.Column
          dataIndex={"floors"}
          key="floors"
          title={t("departments.fields.floor")}
          render={(_floors: IFloor[]) => {
            const floors = _floors ?? [];
            const showFloors = floors.slice(0, DEFAULT_SHOW_FLOORS);
            const remainFloor =
              floors.length > DEFAULT_SHOW_FLOORS
                ? floors.length - DEFAULT_SHOW_FLOORS
                : 0;
            return `${showFloors?.map((floor) => floor.name).join(", ")} ${
              remainFloor > 0 ? `(+${remainFloor})` : ""
            }`;
          }}
        />

        {/* <Table.Column<IDepartment>
          dataIndex="department-status"
          key="department-status"
          title={t("departments.fields.status")}
          render={(_, record) => (
            <Switch
              loading={isUpdating}
              checked={record.status}
              onChange={() => {
                updateStatus({
                  resource: API_RESOURCES.departments,
                  id: record.id || "",
                  values: { ...record, status: !record.status },
                  errorNotification: {
                    type: "error",
                    message: t("departments.notification.editError"),
                  },
                  successNotification: {
                    type: "success",
                    message: t("departments.notification.editSuccess"),
                  },
                });
              }}
            />
          )}
        /> */}

        <Table.Column<IDepartment>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton
                hideText
                size="small"
                recordItemId={record.id}
                errorNotification={{
                  type: "error",
                  message: t("departments.notification.deleteError"),
                }}
                successNotification={{
                  type: "success",
                  message: t("departments.notification.deleteSuccess"),
                }}
              />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
