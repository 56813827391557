import { useGetIdentity } from "@pankod/refine-core";
import { IUserIdentity, IUserRoleCode } from "interfaces";
import { useMemo } from "react";

export default function useUserRole() {
  const { data: user } = useGetIdentity<IUserIdentity>();

  const hasDepartmentRole = useMemo(() => {
    return !!user?.roles?.find(
      (role) => (role as IUserRoleCode) === "DEPARTMENT"
    );
  }, [user]);

  const hasSecurityRole = useMemo(() => {
    return !!user?.roles?.find(
      (role) => (role as IUserRoleCode) === "SECURITY_DEPARTMENT"
    );
  }, [user]);

  const hasAdminRole = useMemo(() => {
    return !!user?.roles?.find((role) => (role as IUserRoleCode) === "ADMIN");
  }, [user]);

  return {
    hasDepartmentRole,
    hasSecurityRole,
    hasAdminRole,
  };
}
