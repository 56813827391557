import {
  Button,
  Form,
  Icons,
  Input,
  Modal,
  useModal,
} from "@pankod/refine-antd";
import { useApiUrl, useCustomMutation } from "@pankod/refine-core";
import { useTranslation } from "react-i18next";

import { PASSWORD_PLACEHOLDER } from "config";
import { IDevice, IUpdateDevicePinTypes } from "interfaces";
import { isOnlyDigit } from "utils/numberHelper";

const { LockOutlined } = Icons;

type ChangePinProps = Pick<IDevice, "code">;

const ChangePin: React.FC<ChangePinProps> = ({ code }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const apiUrl = useApiUrl();
  const { mutate: updatePin, isLoading } =
    useCustomMutation<IUpdateDevicePinTypes>();

  const handleChangePin = async ({ pin }: IUpdateDevicePinTypes) => {
    if (!code) return;

    updatePin(
      {
        url: `${apiUrl}/devices/${code}/admin-pin`,
        method: "put",
        values: {
          pin,
        },
        errorNotification(error: any) {
          const isNotConnected =
            error?.response?.data?.error === "DEVICE_NOT_CONNECTED";

          return {
            type: "error",
            message: isNotConnected
              ? t("devices.change_pin.notification.deviceNotConnected")
              : error?.message ??
                t("devices.change_pin.notification.changePinError"),
          };
        },
        successNotification: {
          type: "success",
          message: t("devices.change_pin.notification.changePinSuccess"),
        },
      },
      {
        onSuccess() {
          close();
        },
      }
    );
  };

  const { modalProps, show, close } = useModal({
    modalProps: {
      title: t("devices.change_pin.title"),
      okText: t("buttons.save"),
      cancelText: t("buttons.cancel"),
      afterClose() {
        form?.resetFields();
      },
      okButtonProps: {
        loading: isLoading,
      },
      onOk: () => {
        form
          .validateFields()
          .then((values) => handleChangePin(values))
          .catch((error) => console.log("error", error));
      },
      onCancel: () => {
        form?.resetFields();
      },
    },
  });

  return (
    <>
      <Button icon={<LockOutlined />} onClick={() => show()}>
        {t("devices.change_pin.button")}
      </Button>

      <Modal {...modalProps}>
        <Form layout="vertical" form={form}>
          <Form.Item
            label={t("devices.change_pin.fields.pin")}
            name="pin"
            rules={[
              {
                required: true,
                message: t("devices.change_pin.errors.pinRequired"),
              },
              () => ({
                validator(_, value) {
                  if (!value) {
                    return Promise.resolve();
                  }
                  if (!isOnlyDigit(value)) {
                    return Promise.reject(
                      new Error(t("devices.change_pin.errors.pinOnlyDigit"))
                    );
                  }
                  if (value.length < 4 || value.length > 8) {
                    return Promise.reject(
                      new Error(t("devices.change_pin.errors.pinMinMaxLength"))
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input.Password
              placeholder={PASSWORD_PLACEHOLDER}
              autoComplete="new-password"
            />
          </Form.Item>

          <Form.Item
            label={t("devices.change_pin.fields.confirmPin")}
            name="confirmPin"
            dependencies={["pin"]}
            rules={[
              {
                required: true,
                message: t("devices.change_pin.errors.confirmPinRequired"),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("pin") === value) {
                    return Promise.resolve();
                  }
                  if (!isOnlyDigit(value)) {
                    return Promise.reject(
                      new Error(t("devices.change_pin.errors.pinOnlyDigit"))
                    );
                  }
                  if (value.length < 4 || value.length > 8) {
                    return Promise.reject(
                      new Error(t("devices.change_pin.errors.pinMinMaxLength"))
                    );
                  }
                  return Promise.reject(
                    new Error(t("devices.change_pin.errors.confirmPinNotMatch"))
                  );
                },
              }),
            ]}
          >
            <Input.Password
              placeholder={PASSWORD_PLACEHOLDER}
              autoComplete="new-password"
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ChangePin;
