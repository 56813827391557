import {
  Col,
  Form,
  Icons,
  Input,
  List,
  Row,
  Table,
  useTable,
} from "@pankod/refine-antd";
import { CrudFilters, HttpError, useTranslate } from "@pankod/refine-core";
import { useDebounceFn } from "ahooks";
import { API_RESOURCES } from "config";

import { IDepartment, IUser } from "interfaces";

const { SearchOutlined } = Icons;

interface IResourceStaffListProps {
  department?: IDepartment;
}

export const DepartmentStaffList: React.FC<IResourceStaffListProps> = ({
  department,
}) => {
  const t = useTranslate();
  const deparmentId = department?.id;

  // const permanentFilter: CrudFilters = deparmentId
  //   ? [{ field: "deparmentId", value: deparmentId, operator: "eq" }]
  //   : [];

  const { tableProps, searchFormProps } = useTable<
    IUser,
    HttpError,
    { q: string }
  >({
    resource: `${API_RESOURCES.staffs}/find_by_department/${deparmentId}`,
    // permanentFilter: permanentFilter,
    onSearch: ({ q }) => {
      const filters: CrudFilters = [];

      filters.push({
        field: "q",
        operator: "eq",
        value: q,
      });

      return filters;
    },
  });

  const { form, ...restFormProps } = searchFormProps;

  const { run: searchStaff } = useDebounceFn(() => form?.submit(), {
    wait: 300,
  });

  return (
    <List
      canCreate={false}
      resource={API_RESOURCES.staffs}
      breadcrumb={null}
      headerProps={{
        style: {
          padding: `50px 0px`,
        },
      }}
    >
      <Row gutter={16}>
        <Col flex="auto">
          <Form layout="vertical" {...restFormProps} form={form}>
            <Form.Item name="q">
              <Input
                style={{ maxWidth: 500 }}
                placeholder={t("placeholders.search")}
                prefix={<SearchOutlined />}
                onChange={() => searchStaff()}
              />
            </Form.Item>
          </Form>
        </Col>
        <Col />
      </Row>

      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="idx" key="idx" title={"#"} />

        <Table.Column
          dataIndex="fullName"
          key="fullName"
          title={t("staffs.fields.name")}
        />

        <Table.Column
          dataIndex="mobile"
          key="mobile"
          title={t("staffs.fields.phone")}
        />

        <Table.Column
          dataIndex="title"
          key="title"
          title={t("staffs.fields.title")}
        />

        <Table.Column
          dataIndex={["department", "name"]}
          key="department"
          title={t("staffs.fields.department")}
        />

        <Table.Column
          dataIndex="email"
          key="email"
          title={t("staffs.fields.email")}
        />
      </Table>
    </List>
  );
};
