import { AntdList, Card, Typography } from "@pankod/refine-antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { DATE_FORMAT } from "config";
import { IVisitorRegistration } from "interfaces";

interface NotificationPopoverContentProps {
  isLoading?: boolean;
  data?: IVisitorRegistration[];
  onShow?: (resource: string, id: string) => void;
}

export const NotificationPopoverContent: React.FC<
  NotificationPopoverContentProps
> = ({ isLoading, data, onShow }) => {
  const { t } = useTranslation();

  return (
    <Card
      bordered={false}
      style={{ width: 450, maxHeight: 500, overflow: "auto" }}
      bodyStyle={{ padding: "20px 24px" }}
    >
      <AntdList
        loading={isLoading}
        dataSource={data ?? []}
        renderItem={(item) => (
          <AntdList.Item
            key={item.id}
            style={{ cursor: "pointer" }}
            onClick={() =>
              item.id && onShow?.("visitors_registrations", item.id)
            }
          >
            <AntdList.Item.Meta
              title={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: 10,
                  }}
                >
                  <Typography.Title
                    level={5}
                    style={{ textAlign: "start", margin: 0 }}
                  >
                    {item.name}
                  </Typography.Title>
                  <Typography.Text style={{ color: "rgba(0,0,0,0.45)" }}>
                    {item.createdAt
                      ? dayjs(item.createdAt).format(DATE_FORMAT)
                      : ""}
                  </Typography.Text>
                </div>
              }
              description={`${t("common.createdBy")}: ${
                typeof item.createdBy === "string"
                  ? item.createdBy
                  : item.createdBy?.name
              }`}
            />
          </AntdList.Item>
        )}
      />
    </Card>
  );
};
