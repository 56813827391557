import { Col, Row, Show } from "@pankod/refine-antd";
import {
  IResourceComponentsProps,
  useShow,
  useTranslate,
} from "@pankod/refine-core";
import TextBox from "components/textBox";

import { IDepartment } from "interfaces";
import { DepartmentStaffList } from "./private/staffList";

export const DepartmentShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { queryResult } = useShow<IDepartment>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <>
      <Show isLoading={isLoading}>
        <Row gutter={[16, 24]}>
          <Col xs={24} md={12}>
            <TextBox
              label={t("departments.fields.name")}
              value={record?.name}
            />
          </Col>

          <Col xs={24} md={12}>
            <TextBox
              label={t("departments.fields.code")}
              value={record?.code}
            />
          </Col>

          <Col xs={24} md={12}>
            <TextBox
              label={t("departments.fields.area")}
              value={record?.areas?.map(area => area.name).join(", ") ?? "--"}
            />
          </Col>

          <Col xs={24} md={12}>
            <TextBox
              label={t("departments.fields.floor")}
              value={record?.floors?.map(floor => floor.name).join(", ") ?? "--"}
            />
          </Col>

          <Col xs={24}>
            <TextBox
              label={t("departments.fields.description")}
              value={record?.description || ""}
              valueStyles={{
                minHeight: 88,
              }}
            />
          </Col>
        </Row>

        {record?.id && <DepartmentStaffList department={record} />}
      </Show>
    </>
  );
};
