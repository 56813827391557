import {
  Col,
  CreateButton,
  DeleteButton,
  EditButton,
  Form,
  Icons,
  Input,
  List,
  Row,
  Space,
  Table,
  useTable,
} from "@pankod/refine-antd";
import {
  CrudFilters,
  HttpError,
  IResourceComponentsProps,
  useTranslate,
} from "@pankod/refine-core";
import { useDebounceFn } from "ahooks";
import { DATE_TIME_FORMAT } from "config";
import dayjs from "dayjs";

import { IRfid } from "interfaces";

const { SearchOutlined, PlusOutlined } = Icons;

export const RfidList: React.FC<IResourceComponentsProps> = ({ canCreate }) => {
  const t = useTranslate();

  const { tableProps, searchFormProps } = useTable<
    IRfid,
    HttpError,
    { q: string }
  >({
    onSearch: ({ q }) => {
      const filters: CrudFilters = [];

      filters.push({
        field: "q",
        operator: "eq",
        value: q,
      });

      return filters;
    },
  });

  const { form, ...restFormProps } = searchFormProps;

  const { run: searchDevice } = useDebounceFn(() => form?.submit(), {
    wait: 300,
  });

  // const { mutate: updateStatus, isLoading: isUpdating } = useUpdate<IDevice>();

  return (
    <List canCreate={false}>
      <Row gutter={16}>
        <Col flex="auto">
          <Form layout="vertical" {...restFormProps} form={form}>
            <Form.Item name="q">
              <Input
                style={{ maxWidth: 500 }}
                placeholder={t("placeholders.search")}
                prefix={<SearchOutlined />}
                onChange={() => searchDevice()}
              />
            </Form.Item>
          </Form>
        </Col>
        <Col>
          {canCreate && (
            <CreateButton type="primary" icon={<PlusOutlined />}>
              {t("buttons.create_new")}
            </CreateButton>
          )}
        </Col>
      </Row>

      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="idx" key="idx" title={"#"} />

        <Table.Column
          dataIndex="number"
          key="number"
          title={t("rfids.fields.number")}
        />

        <Table.Column
          dataIndex="exp"
          key="exp"
          title={t("rfids.fields.exp")}
          render={(exp) => dayjs(exp).format(DATE_TIME_FORMAT)}
        />

        <Table.Column<IRfid>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <DeleteButton
                hideText
                size="small"
                recordItemId={record.id}
                errorNotification={{
                  type: "error",
                  message: t("rfids.notification.deleteError"),
                }}
                successNotification={{
                  type: "success",
                  message: t("rfids.notification.deleteSuccess"),
                }}
              />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
