import {
  Button,
  Card,
  Form,
  Icons,
  Input,
  PageHeader,
  Space,
  Spin,
} from "@pankod/refine-antd";
import {
  useApiUrl,
  useCustomMutation,
  useTranslate,
} from "@pankod/refine-core";

import { WithSidebar } from "components/layout";
import { PASSWORD_PLACEHOLDER } from "config";
import { IUpdatePasswordTypes } from "interfaces";

const { SaveOutlined } = Icons;

export const UpdatePasswordPage: React.FC = () => {
  const t = useTranslate();
  const [form] = Form.useForm();
  const apiUrl = useApiUrl();

  const { mutate: updatePassword, isLoading } =
    useCustomMutation<IUpdatePasswordTypes>();

  const handleChangePassword = async ({
    password,
    newPassword,
  }: IUpdatePasswordTypes) => {
    updatePassword({
      url: `${apiUrl}/users/reset_passwd`,
      method: "post",
      values: {
        currPass: password,
        newPass: newPassword,
      },
      errorNotification: {
        type: "error",
        message: t("pages.changePassword.notification.change_password_error"),
      },
      successNotification: {
        type: "success",
        message: t("pages.changePassword.notification.change_password_success"),
      },
    });
  };

  return (
    <WithSidebar>
      <PageHeader title={t("pages.changePassword.title")}>
        <Spin spinning={isLoading}>
          <Card
            bordered={false}
            actions={[
              <Space
                key="footer-buttons"
                wrap
                style={{
                  float: "right",
                  marginRight: 24,
                }}
              >
                <Button
                  type="primary"
                  icon={<SaveOutlined />}
                  onClick={() => {
                    form
                      .validateFields()
                      .then((values) => handleChangePassword(values))
                      .catch((error) => console.log("error", error));
                  }}
                >
                  {t("buttons.save")}
                </Button>
              </Space>,
            ]}
          >
            <Form<IUpdatePasswordTypes> layout="vertical" form={form}>
              <Form.Item
                label={t("pages.changePassword.fields.current_pass")}
                name="password"
                rules={[
                  {
                    required: true,
                    message: t("pages.changePassword.errors.password_required"),
                  },
                ]}
              >
                <Input.Password
                  placeholder={PASSWORD_PLACEHOLDER}
                  autoComplete="current-password"
                />
              </Form.Item>

              <Form.Item
                label={t("pages.changePassword.fields.new_pass")}
                name="newPassword"
                rules={[
                  {
                    required: true,
                    message: t("pages.changePassword.errors.new_pass_required"),
                  },
                ]}
              >
                <Input.Password
                  placeholder={PASSWORD_PLACEHOLDER}
                  autoComplete="new-password"
                />
              </Form.Item>

              <Form.Item
                label={t("pages.changePassword.fields.confirm_pass")}
                name="confirmPassword"
                dependencies={["newPassword"]}
                rules={[
                  {
                    required: true,
                    message: t(
                      "pages.changePassword.errors.confirm_pass_required"
                    ),
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("newPassword") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          t(
                            "pages.changePassword.errors.confirm_pass_not_match"
                          )
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  placeholder={PASSWORD_PLACEHOLDER}
                  autoComplete="new-password"
                />
              </Form.Item>
            </Form>
          </Card>
        </Spin>
      </PageHeader>
    </WithSidebar>
  );
};
