import {
  AntdLayout,
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Typography,
} from "@pankod/refine-antd";
import { useLogin, useRouterContext, useTranslate } from "@pankod/refine-core";
import { PASSWORD_PLACEHOLDER } from "config";
import { ILoginTypes } from "interfaces";
import React from "react";

import { Title as RenderToTitle } from "../../components/layout";

const { Title } = Typography;
const { Sider } = AntdLayout;

export const LoginPage: React.FC = () => {
  const t = useTranslate();

  const { Link } = useRouterContext();

  const [form] = Form.useForm<ILoginTypes>();

  const { mutate: login, isLoading } = useLogin<ILoginTypes>();

  const CardTitle = (
    <Title
      level={3}
      style={{
        textAlign: "center",
        fontSize: "24px",
        letterSpacing: "-0.04em",
        overflowWrap: "break-word",
        hyphens: "manual",
        textOverflow: "unset",
        whiteSpace: "pre-wrap",
      }}
    >
      {t("pages.login.title", "Sign in to your account")}
    </Title>
  );

  const CardContent = (
    <Card
      title={CardTitle}
      headStyle={{ borderBottom: 0 }}
      style={{
        minWidth: "320px",
        maxWidth: "408px",
        margin: "auto",
      }}
    >
      <Form<ILoginTypes>
        layout="vertical"
        form={form}
        onFinish={(values) => login({ ...values, translate: t })}
        requiredMark={false}
        initialValues={{
          remember: false,
        }}
      >
        <Form.Item
          name="username"
          label={t("pages.login.fields.username")}
          rules={[
            {
              required: true,
              message: t("pages.login.errors.usernameRequired"),
            },
          ]}
        >
          <Input
            size="large"
            placeholder={t("pages.login.fields.username")}
            autoComplete="username"
          />
        </Form.Item>

        <Form.Item
          name="password"
          label={t("pages.login.fields.password", "Password")}
          rules={[
            { required: true, message: t("pages.login.errors.passRequired") },
          ]}
          style={{ marginBottom: "12px" }}
        >
          <Input.Password
            type="password"
            placeholder={PASSWORD_PLACEHOLDER}
            size="large"
            autoComplete="current-password"
          />
        </Form.Item>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "12px",
          }}
        >
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox
              style={{
                fontSize: "12px",
              }}
            >
              {t("pages.login.buttons.rememberMe", "Remember me")}
            </Checkbox>
          </Form.Item>
          <Link
            style={{
              fontSize: "12px",
              marginLeft: "auto",
            }}
            to="/forgot-password"
          >
            {t("pages.login.buttons.forgotPassword", "Forgot password?")}
          </Link>
        </div>

        <Form.Item>
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            loading={isLoading}
            block
          >
            {t("pages.login.signin", "Sign in")}
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );

  return (
    <AntdLayout style={{ height: "100vh" }}>
      <Sider breakpoint="lg" width={300} collapsedWidth={0} trigger={null}>
        <RenderToTitle collapsed={false} />
        <Col>
          <Title level={3} style={{ color: "#fff", textAlign: "center" }}>
            {t("site.title")}
          </Title>
        </Col>
      </Sider>

      <AntdLayout style={{ margin: "auto" }}>
        <AntdLayout style={{ padding: 15 }}>{CardContent}</AntdLayout>
      </AntdLayout>
    </AntdLayout>
  );
};
