import { Badge, Icons, Popover } from "@pankod/refine-antd";
import { useApiUrl, useCustom, useNavigation } from "@pankod/refine-core";
import { useEffect, useState } from "react";

import { IVisitorRegistration } from "interfaces";
import { NotificationPopoverContent } from "./NotificationPopoverContent";

const { BellFilled } = Icons;

const DEFAULT_INTERVAL_FETCH_DATA = 1 * 60 * 1000;
const DEFAULT_OVERFLOW_COUNT = 99;

export const NotificationPopover: React.FC = () => {
  const apiUrl = useApiUrl();
  const { show } = useNavigation();
  const [isShow, setIsShow] = useState(false);

  const { data, isLoading, refetch } = useCustom<IVisitorRegistration[]>({
    url: `${apiUrl}/visitors_registrations/unread`,
    method: "get",
  });

  useEffect(() => {
    const intervalId = setInterval(
      () => refetch(),
      DEFAULT_INTERVAL_FETCH_DATA
    );
    return () => clearInterval(intervalId);
  }, [refetch]);

  return (
    <Popover
      title=""
      trigger="click"
      showArrow={false}
      visible={isShow}
      onVisibleChange={setIsShow}
      placement="bottomRight"
      content={
        <NotificationPopoverContent
          data={data?.data}
          isLoading={isLoading}
          onShow={(resource, id) => {
            show(resource, id);
            setIsShow(false);
            setTimeout(() => refetch(), 1000);
          }}
        />
      }
    >
      <span style={{ cursor: "pointer" }}>
        {data?.data?.length ? (
          <Badge
            size="small"
            count={data?.data?.length}
            overflowCount={DEFAULT_OVERFLOW_COUNT}
          >
            <BellFilled style={{ fontSize: 20, color: "#172B4D" }} />
          </Badge>
        ) : (
          <BellFilled style={{ fontSize: 20, color: "#172B4D" }} />
        )}
      </span>
    </Popover>
  );
};
