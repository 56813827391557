import {
  Col,
  Image,
  PageHeader,
  Row,
  Space,
  Tag,
  Typography,
} from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";

import TextBox from "components/textBox";
import { IPersonAccess } from "interfaces";
import { Fragment } from "react";
import { sortFloors } from "utils/commonHelper";

const { Text, Paragraph } = Typography;

interface IPersonAccessProps {
  personAccess?: IPersonAccess;
}

export const PersonAccess: React.FC<IPersonAccessProps> = ({
  personAccess,
}) => {
  const t = useTranslate();

  return (
    <Row gutter={[16, 24]}>
      <Col xs={24}>
        <PageHeader
          backIcon={null}
          style={{ padding: "20px 0px 0px" }}
          title={
            <Text style={{ fontSize: 16 }}>
              {t("staffs.fields.userAccess.header")}
            </Text>
          }
        />
      </Col>

      <Col xs={24} md={12}>
        <TextBox
          label={t("staffs.fields.userAccess.accessCode")}
          value={personAccess?.accessCode || "--"}
        />
      </Col>

      <Col xs={24} md={12}></Col>

      <Col xs={24}>
        <Space
          direction="horizontal"
          style={{ marginBottom: 10, width: "100%" }}
        >
          <TextBox label={t("staffs.fields.userAccess.area")} value={null} />
          <Tag color="green">{personAccess?.areas?.length}</Tag>
        </Space>
        {personAccess?.areas?.map((area) => (
          <Paragraph key={area.id}>• {area.name}</Paragraph>
        ))}
      </Col>

      <Col xs={24}>
        <Space
          direction="horizontal"
          style={{ marginBottom: 10, width: "100%" }}
        >
          <TextBox label={t("staffs.fields.userAccess.floor")} value={null} />
          <Tag color="green">{personAccess?.floors?.length}</Tag>
        </Space>
        {sortFloors(personAccess?.floors ?? []).map((floor) => (
          <Paragraph key={floor.id}>• {floor.name}</Paragraph>
        ))}
      </Col>

      <Col xs={24}>
        <Space
          direction="horizontal"
          style={{ marginBottom: 10, width: "100%" }}
        >
          <TextBox label={t("staffs.fields.userAccess.card")} value={null} />
          <Tag color="green">{personAccess?.cards?.length}</Tag>
        </Space>
        {personAccess?.cards?.map((card) => (
          <Paragraph key={card.id}>{card.numberBase64}</Paragraph>
        ))}
      </Col>

      <Col xs={24}>
        <Space
          direction="horizontal"
          style={{ marginBottom: 10, width: "100%" }}
        >
          <TextBox label={t("staffs.fields.userAccess.face")} value={null} />
          <Tag color="green">{personAccess?.faces?.length}</Tag>
        </Space>
        <Image.PreviewGroup>
          {personAccess?.faces?.map((face) => (
            <Fragment key={face.id}>
              {face.image?.url && <Image width={200} src={face.image?.url} />}
            </Fragment>
          ))}
        </Image.PreviewGroup>
      </Col>

      <Col xs={24}>
        <Space
          direction="horizontal"
          style={{ marginBottom: 10, width: "100%" }}
        >
          <TextBox
            label={t("staffs.fields.userAccess.fingerprint")}
            value={null}
          />
          <Tag color="green">{personAccess?.fingers?.length}</Tag>
        </Space>
        <Image.PreviewGroup>
          {personAccess?.fingers?.map((face) => (
            <Fragment key={face.id}>
              {face.image?.url && <Image width={200} src={face.image?.url} />}
            </Fragment>
          ))}
        </Image.PreviewGroup>
      </Col>
    </Row>
  );
};
