import { Col, Create, Form, Input, Row, useForm } from "@pankod/refine-antd";
import { IResourceComponentsProps, useTranslate } from "@pankod/refine-core";
import dayjs from "dayjs";

import DatePicker from "components/datepicker";
import { DATE_TIME_FORMAT } from "config";
import { IRfid } from "interfaces";

export const RfidCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { formProps, saveButtonProps, formLoading } = useForm<IRfid>({
    errorNotification: {
      type: "error",
      message: t("rfids.notification.createError"),
    },
    successNotification: {
      type: "success",
      message: t("rfids.notification.createSuccess"),
    },
  });

  return (
    <Create saveButtonProps={saveButtonProps} isLoading={formLoading}>
      <Form {...formProps} layout="vertical">
        <Row gutter={[16, 24]}>
          <Col xs={24} md={12}>
            <Form.Item
              label={t("rfids.fields.number")}
              name="number"
              rules={[
                {
                  required: true,
                  message: t("rfids.errors.numberRequired"),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              label={t("rfids.fields.exp")}
              name="exp"
              getValueProps={(value) => ({
                value: value ? dayjs(value) : "",
              })}
              rules={[
                {
                  required: true,
                  message: t("rfids.errors.expRequired"),
                },
              ]}
            >
              <DatePicker
                format={DATE_TIME_FORMAT}
                style={{ width: "100%" }}
                showTime
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Create>
  );
};
