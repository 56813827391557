import {
  Button,
  Col,
  Create,
  Form,
  Icons,
  Input,
  Modal,
  PageHeader,
  Row,
  useForm,
  useModalForm,
  Typography,
  Select,
  useSelect,
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useTranslate } from "@pankod/refine-core";
import dayjs from "dayjs";
import { useState } from "react";

import DatePicker from "components/datepicker";
import { IArea, IVisitor, IVisitorRegistration } from "interfaces";

import { DATE_TIME_FORMAT } from "config";
import { VisitorTable } from "./private/visitorTable";
import { VISITORS_KEY } from "./private/constants";
import { AddVisitorFormContent } from "./private/addVisitorFormContent";
import { fuzzySearch } from "utils/searchHelper";
import { toLowerCaseNonAccentVietnamese } from "utils/vietnameseHelper";

const { Text } = Typography;
const { PlusOutlined } = Icons;

export const VisitorRegistrationCreate: React.FC<
  IResourceComponentsProps
> = () => {
  const t = useTranslate();

  const {
    formProps: { form: formRegistration, ...restFormRegistration },
    saveButtonProps,
    formLoading,
  } = useForm<IVisitorRegistration>({
    errorNotification: {
      type: "error",
      message: t("visitors_registrations.notification.createError"),
    },
    successNotification: {
      type: "success",
      message: t("visitors_registrations.notification.createSuccess"),
    },
  });

  const {
    modalProps,
    formProps: { onFinish: _, form: formAddVisitor, ...restModalFormProps },
    show: showAddVisitor,
    close: closeAddVisitor,
    visible: visibleAddVisitor,
  } = useModalForm({
    action: "create",
  });

  const [visitorList, setVisitorList] = useState<IVisitor[]>([]);

  const addOrEditVisitor = async ({ key, ...rest }: IVisitor) => {
    const newVisitorList = !key
      ? visitorList.concat({
          key: visitorList.length + 1,
          ...rest,
        })
      : visitorList.map((visitor) => {
          if (visitor.key === key) {
            return {
              key,
              ...rest,
            };
          }
          return visitor;
        });

    setVisitorList(newVisitorList);
    formRegistration?.setFieldsValue({ [VISITORS_KEY]: newVisitorList });

    closeAddVisitor();
    formAddVisitor?.resetFields();
  };

  const deleteVisitor = async (record: IVisitor) => {
    const newVisitorList = visitorList
      .filter((visitor) => visitor.key !== record.key)
      .map((visitor, index) => ({
        ...visitor,
        key: index + 1,
      }));

    setVisitorList(newVisitorList);
    formRegistration?.setFieldsValue({ [VISITORS_KEY]: newVisitorList });
  };

  const {
    selectProps: { onSearch, showSearch, ...restSelectAreaProps },
  } = useSelect<IArea>({
    resource: "areas",
    optionLabel: "name",
    pagination: {
      current: 1,
      pageSize: 99999, // use the large PageSize to get full
    },
  });

  return (
    <>
      <Create
        saveButtonProps={{
          ...saveButtonProps,
          disabled: visitorList.length === 0,
        }}
        isLoading={formLoading}
      >
        <Form
          form={formRegistration}
          {...restFormRegistration}
          layout="vertical"
        >
          <Row gutter={[16, 24]}>
            <Col xs={24} md={12}>
              <Form.Item
                label={t("visitors_registrations.fields.name")}
                name="name"
                rules={[
                  {
                    required: true,
                    message: t("visitors_registrations.errors.nameRequired"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                label={t("visitors_registrations.fields.area")}
                name="areas"
                rules={[
                  {
                    required: true,
                    message: t("visitors_registrations.errors.areaRequired"),
                  },
                ]}
              >
                <Select
                  {...restSelectAreaProps}
                  showSearch={true}
                  mode="multiple"
                  filterOption={(input, option) =>
                    fuzzySearch(
                      toLowerCaseNonAccentVietnamese(
                        String(option?.label ?? "")
                      ),
                      toLowerCaseNonAccentVietnamese(input)
                    )
                  }
                />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                label={t("visitors_registrations.fields.startTime")}
                name="startTime"
                getValueProps={(value) => ({
                  value: value ? dayjs(value) : "",
                })}
                rules={[
                  {
                    required: true,
                    message: t(
                      "visitors_registrations.errors.startTimeRequired"
                    ),
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  format={DATE_TIME_FORMAT}
                  showTime
                  disabledDate={(d) => {
                    const today = new Date();
                    return d && d.isBefore(today) && !d.isSame(today, "day");
                  }}
                />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                label={t("visitors_registrations.fields.endTime")}
                name="endTime"
                getValueProps={(value) => ({
                  value: value ? dayjs(value) : "",
                })}
                rules={[
                  {
                    required: true,
                    message: t("visitors_registrations.errors.endTimeRequired"),
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  format={DATE_TIME_FORMAT}
                  showTime
                  disabledDate={(d) => {
                    const today = new Date();
                    return d && d.isBefore(today) && !d.isSame(today, "day");
                  }}
                />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item name={VISITORS_KEY}>
                <Input hidden />
              </Form.Item>
            </Col>

            <Col xs={24}>
              <PageHeader
                backIcon={null}
                style={{ padding: 0 }}
                title={
                  <Text
                    style={{
                      fontSize: 16,
                    }}
                  >
                    {t("visitors_registrations.visitorList.titles.list")}
                  </Text>
                }
                extra={[
                  <Button
                    key="add-visitor"
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => showAddVisitor()}
                  >
                    {t("visitors_registrations.visitorList.buttons.addVisitor")}
                  </Button>,
                ]}
              />
            </Col>

            <Col xs={24}>
              <VisitorTable
                visitorList={visitorList}
                onEdit={(record) => {
                  showAddVisitor();
                  setTimeout(
                    () => formAddVisitor?.setFieldsValue({ ...record }),
                    0
                  );
                }}
                onDelete={(record) => {
                  deleteVisitor(record);
                }}
              />
            </Col>
          </Row>
        </Form>
      </Create>

      <Modal
        {...modalProps}
        title={t("visitors_registrations.visitorList.fields.name")}
      >
        <Form
          {...restModalFormProps}
          layout="vertical"
          form={formAddVisitor}
          onFinish={() =>
            formAddVisitor
              ?.validateFields()
              .then((values) => addOrEditVisitor(values))
              .catch((error) => console.log("error", error))
          }
        >
          {visibleAddVisitor && <AddVisitorFormContent />}
        </Form>
      </Modal>
    </>
  );
};
