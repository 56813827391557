import { Col, Row } from "@pankod/refine-antd";

import { Layout } from "components/layout";
import { CSSProperties } from "react";

interface WithSidebarProps {
  children: React.ReactNode;
  rowStyles?: CSSProperties;
}

export const WithSidebar: React.FC<WithSidebarProps> = ({
  children,
  rowStyles,
}) => {
  return (
    <Layout>
      <Row
        style={{
          borderRadius: 6,
          backgroundColor: "#fff",
          ...(rowStyles || {}),
        }}
      >
        <Col span={24}>{children}</Col>
      </Row>
    </Layout>
  );
};
