import { Col, Form, Input, Row } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { UploadImage } from "components/upload";
import { getFileUrlsFromEvent, getFileValueProps } from "utils/formHelper";
import { isValidPhoneNumb } from "utils/phoneNumberHelper";

export const AddVisitorFormContent: React.FC = () => {
  const t = useTranslate();

  return (
    <Row gutter={[16, 24]}>
      <Col xs={24} md={12}>
        <Form.Item
          label={t("visitors_registrations.visitorList.fields.name")}
          name="fullName"
          rules={[
            {
              required: true,
              message: t(
                "visitors_registrations.visitorList.errors.nameRequired"
              ),
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Col>

      <Col xs={24} md={12}>
        <Form.Item
          label={t(
            "visitors_registrations.visitorList.fields.citizenIdentityNo"
          )}
          name="citizenIdentityNo"
          rules={[
            {
              required: true,
              message: t(
                "visitors_registrations.visitorList.errors.citizenIdentityNoRequired"
              ),
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Col>

      <Col xs={24} md={12}>
        <Form.Item
          label={t("visitors_registrations.visitorList.fields.mobile")}
          name="mobile"
          rules={[
            {
              required: true,
              message: t(
                "visitors_registrations.visitorList.errors.mobileRequired"
              ),
            },
            {
              validator: (_, value) => {
                if (isValidPhoneNumb(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(
                    t("visitors_registrations.visitorList.errors.mobileInvalid")
                  )
                );
              },
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Col>

      <Col xs={24} md={12}>
        <Form.Item
          label={t("visitors_registrations.visitorList.fields.address")}
          name="address"
          rules={[
            {
              required: true,
              message: t(
                "visitors_registrations.visitorList.errors.addressRequired"
              ),
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Col>

      <Col xs={24} md={12}>
        <Form.Item
          label={t("visitors_registrations.visitorList.fields.vehicle_id")}
          name="vehicle_id"
        >
          <Input />
        </Form.Item>
      </Col>

      <Col xs={24} md={12}>
        <Form.Item name="key">
          <Input hidden />
        </Form.Item>
      </Col>

      <Col xs={24} md={12}>
        <Form.Item
          label={t(
            "visitors_registrations.visitorList.fields.citizenIdFontImage"
          )}
          name="citizenIdFontImage"
          getValueFromEvent={(e) => {
            const fileUrls = getFileUrlsFromEvent(e);
            return fileUrls.length < 1 ? "" : fileUrls[0];
          }}
          getValueProps={(value) => {
            const imgs = getFileValueProps(value, [
              t(`visitors_registrations.visitorList.fields.citizenIdFontImage`),
            ]);
            return {
              defaultFileList: imgs,
            };
          }}
        >
          <UploadImage maxCount={1} />
        </Form.Item>
      </Col>

      <Col xs={24} md={12}>
        <Form.Item
          label={t(
            "visitors_registrations.visitorList.fields.citizenIdBackImage"
          )}
          name="citizenIdBackImage"
          getValueFromEvent={(e) => {
            const fileUrls = getFileUrlsFromEvent(e);
            return fileUrls.length < 1 ? "" : fileUrls[0];
          }}
          getValueProps={(value) => {
            const imgs = getFileValueProps(value, [
              t(`visitors_registrations.visitorList.fields.citizenIdBackImage`),
            ]);
            return {
              defaultFileList: imgs,
            };
          }}
        >
          <UploadImage maxCount={1} />
        </Form.Item>
      </Col>
    </Row>
  );
};
