import { Avatar, AvatarProps } from "@pankod/refine-antd";
import createAvatar from "utils/createAvatar";

interface UserAvatarProps extends AvatarProps {
  name: string;
}

export const UserAvatar: React.FC<UserAvatarProps> = ({
  name,
  src,
  size,
  ...rest
}) => {
  return (
    <Avatar
      src={src}
      size={size}
      style={{
        backgroundColor: createAvatar(name).color,
      }}
      {...rest}
    >
      {createAvatar(name).name}
    </Avatar>
  );
};
