import {
  Col,
  CreateButton,
  DeleteButton,
  EditButton,
  FilterDropdown,
  Form,
  Icons,
  Input,
  List,
  Row,
  Select,
  ShowButton,
  Space,
  // Switch,
  Table,
  useSelect,
  // TextField,
  useTable,
} from "@pankod/refine-antd";
import {
  CrudFilters,
  HttpError,
  IResourceComponentsProps,
  useTranslate,
} from "@pankod/refine-core";
import { useDebounceFn } from "ahooks";
import {
  ButtonRegisterCard,
  ButtonRegisterFace,
  ButtonRegisterFinger,
} from "components/registers";

// import { API_RESOURCES, ROLE_LIST } from "config";
import { IDepartment, IStaff } from "interfaces";
import { useMemo } from "react";
import { AttendanceExport } from "./private/attendanceExport";

const { SearchOutlined, PlusOutlined } = Icons;

export const StaffList: React.FC<IResourceComponentsProps> = ({
  canCreate,
}) => {
  const t = useTranslate();

  const { tableProps, searchFormProps } = useTable<
    IStaff,
    HttpError,
    { q: string }
  >({
    onSearch: ({ q }) => {
      const filters: CrudFilters = [];

      filters.push({
        field: "q",
        operator: "eq",
        value: q,
      });

      return filters;
    },
  });

  const { form, ...restFormProps } = searchFormProps;

  // const { mutate: updateStatus, isLoading: isUpdating } = useUpdate<IStaff>();

  const { run: searchUser } = useDebounceFn(() => form?.submit(), {
    wait: 300,
  });

  // const invalidate = useInvalidate();

  // const handleUpdateAllowedAreaSuccess = useCallback(() => {
  //   invalidate({
  //     resource: "staffs",
  //     invalidates: ["list"],
  //   });
  // }, [invalidate]);

  const {
    selectProps: { showSearch, onSearch, ...restSelectDepartmentProps },
  } = useSelect<IDepartment>({
    resource: "departments",
    optionLabel: "name",
    pagination: {
      current: 1,
      pageSize: 99999, // use the large PageSize to get full
    },
  });

  const departmentOptions = useMemo(
    () => restSelectDepartmentProps.options,
    [restSelectDepartmentProps]
  );

  return (
    <List canCreate={false}>
      <Row gutter={16}>
        <Col flex="auto">
          <Form layout="vertical" {...restFormProps} form={form}>
            <Form.Item name="q">
              <Input
                style={{ maxWidth: 500 }}
                placeholder={t("placeholders.search")}
                prefix={<SearchOutlined />}
                onChange={() => searchUser()}
              />
            </Form.Item>
          </Form>
        </Col>
        <Col>
          <Space>
            <AttendanceExport />
            {canCreate && (
              <CreateButton type="primary" icon={<PlusOutlined />}>
                {t("buttons.create_new")}
              </CreateButton>
            )}
          </Space>
        </Col>
      </Row>

      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="idx" key="idx" title={"#"} />

        <Table.Column
          dataIndex="fullName"
          key="fullName"
          title={t("staffs.fields.name")}
        />

        <Table.Column
          dataIndex="mobile"
          key="mobile"
          title={t("staffs.fields.phone")}
        />

        {/* <Table.Column
          dataIndex="title"
          key="title"
          title={t("staffs.fields.title")}
        /> */}

        <Table.Column
          dataIndex={["department", "name"]}
          key="department"
          title={t("staffs.fields.department")}
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Select
                style={{ minWidth: 250 }}
                placeholder={t("staffs.fields.department")}
                options={departmentOptions}
              />
            </FilterDropdown>
          )}
        />

        {/* <Table.Column
          dataIndex="email"
          key="email"
          title={t("staffs.fields.email")}
        /> */}

        {/* <Table.Column
          dataIndex="image"
          key="image"
          title={t("staffs.fields.image")}
          render={(image) => image && <Avatar src={<Image src={image} />} />}
        /> */}

        {/* <Table.Column<IStaff>
          dataIndex="staff-status"
          key="staff-status"
          title={t("staffs.fields.status")}
          render={(_, record) => (
            <Switch
              loading={isUpdating}
              checked={record.status}
              onChange={() => {
                updateStatus({
                  resource: API_RESOURCES.staffs,
                  id: record.id || "",
                  values: { ...record, status: !record.status },
                  errorNotification: {
                    type: "error",
                    message: t("staffs.notification.editError"),
                  },
                  successNotification: {
                    type: "success",
                    message: t("staffs.notification.editSuccess"),
                  },
                });
              }}
            />
          )}
        /> */}

        {/* <Table.Column<IStaff>
          dataIndex="allowed_areas"
          key="allowed_areas"
          title={t("staffs.fields.allowed_areas")}
          render={(_, record) => (
            <ButtonSelectAllowedAreas
              userAccess={record.userAccess}
              onSuccess={handleUpdateAllowedAreaSuccess}
            />
          )}
        /> */}

        <Table.Column<IStaff>
          dataIndex="registers"
          title={t("staffs.fields.registers")}
          render={(_, record) => (
            <Space>
              <ButtonRegisterCard
                userAccess={record.userAccess}
                username={record.fullName}
              />
              <ButtonRegisterFace
                userAccess={record.userAccess}
                username={record.fullName}
              />
              <ButtonRegisterFinger
                userAccess={record.userAccess}
                username={record.fullName}
              />
            </Space>
          )}
        />

        <Table.Column<IStaff>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton
                hideText
                size="small"
                recordItemId={record.id}
                errorNotification={{
                  type: "error",
                  message: t("staffs.notification.deleteError"),
                }}
                successNotification={{
                  type: "success",
                  message: t("staffs.notification.deleteSuccess"),
                }}
              />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
