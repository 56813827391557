import { Space, Typography } from "@pankod/refine-antd";
import { CSSProperties } from "react";

const { Text } = Typography;

interface TextBoxProps {
  value?: React.ReactNode;
  label?: string;
  valueStyles?: CSSProperties;
  labelStyles?: CSSProperties;
  wrapperStyles?: CSSProperties;
}

const TextBox: React.FC<TextBoxProps> = ({
  value,
  label,
  valueStyles,
  labelStyles,
  wrapperStyles,
}) => {
  return (
    <Space
      direction="vertical"
      style={{ width: "100%", ...(wrapperStyles || {}) }}
    >
      {label && (
        <Text
          style={{
            color: "#7f879e",
            ...(labelStyles || {}),
          }}
        >
          {label}
        </Text>
      )}
      {value !== null && (
        <div
          style={{
            backgroundColor: "#f6f8f9",
            border: "1px solid #f8f8fd",
            borderRadius: "4px",
            padding: "5px 12px",
            width: "100%",
            ...(valueStyles || {}),
          }}
        >
          {value}
        </div>
      )}
    </Space>
  );
};

export default TextBox;
