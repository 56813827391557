import { DatePicker as AntDatePicker } from "@pankod/refine-antd";
import {
  PickerProps,
  RangePickerProps,
} from "antd/lib/date-picker/generatePicker";
import { Dayjs } from "dayjs";

import viVn from "antd/es/date-picker/locale/vi_VN";
import "dayjs/locale/vi";

const DatePicker = (props: PickerProps<Dayjs>) => (
  <AntDatePicker {...props} locale={viVn} />
);

export const RangePicker = (props: RangePickerProps<Dayjs>) => (
  <AntDatePicker.RangePicker {...props} locale={viVn} />
);

export default DatePicker;
