import {
  Button,
  Col,
  Input,
  PageHeader,
  Popconfirm,
  Row,
  Show,
  Space,
  Tag,
  Typography,
} from "@pankod/refine-antd";
import {
  IResourceComponentsProps,
  useShow,
  useTranslate,
  useCustomMutation,
  useApiUrl,
  useNavigation,
} from "@pankod/refine-core";
import dayjs from "dayjs";

import TextBox from "components/textBox";
import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  VISITOR_REGISTRATION_STATUS_LIST,
} from "config";
import { IUser, IVisitorRegistration } from "interfaces";
import { VisitorTable } from "./private/visitorTable";
import useUserRole from "hooks/useUserRole";
import { useEffect, useMemo, useState } from "react";

const { Text } = Typography;

export const VisitorRegistrationShow: React.FC<
  IResourceComponentsProps
> = () => {
  const t = useTranslate();

  const { hasSecurityRole, hasDepartmentRole } = useUserRole();

  const { goBack } = useNavigation();

  const { queryResult } = useShow<IVisitorRegistration>();

  const { data, isLoading } = queryResult;

  const record = data?.data;

  const visitorList =
    record?.visitors?.map((visitor, index) => ({
      ...visitor,
      key: index + 1,
    })) ?? [];

  const initComment = useMemo(() => record?.comment || "", [record]);

  const [comment, setComment] = useState(initComment);

  useEffect(() => setComment(initComment), [initComment]);

  const canApproveOrReject = useMemo(
    () => record?.status === "wait_for_approved",
    [record]
  );

  const { mutate: updateApproveStatus } =
    useCustomMutation<IVisitorRegistration>();

  const apiUrl = useApiUrl();

  const doApproveOrReject = async (
    record: IVisitorRegistration,
    isApprove: boolean
  ) => {
    updateApproveStatus(
      {
        url: `${apiUrl}/visitors_registrations/action`,
        method: "post",
        values: {
          visitorRegistration: { ...record, comment },
          action: isApprove ? "approved" : "rejected",
        },
        errorNotification: {
          type: "error",
          message: isApprove
            ? t("visitors_registrations.notification.approveError")
            : t("visitors_registrations.notification.rejectError"),
        },
        successNotification: {
          type: "success",
          message: isApprove
            ? t("visitors_registrations.notification.approveSuccess")
            : t("visitors_registrations.notification.rejectSuccess"),
        },
      },
      {
        onSuccess() {
          goBack();
        },
      }
    );
  };

  return (
    <>
      <Show
        isLoading={isLoading}
        canEdit={record?.status === "created" && hasDepartmentRole}
      >
        <Row gutter={[16, 24]}>
          <Col xs={24} md={12}>
            <TextBox
              label={t("visitors_registrations.fields.name")}
              value={record?.name}
            />
          </Col>

          <Col xs={24} md={12}>
            <TextBox
              label={t("visitors_registrations.fields.area")}
              value={
                <>
                  {record?.area?.map((area) => (
                    <Tag key={area.id}>{area.name}</Tag>
                  ))}
                </>
              }
            />
          </Col>

          <Col xs={24} md={12}>
            <TextBox
              label={t("visitors_registrations.fields.startTime")}
              value={
                record?.startTime
                  ? dayjs(record?.startTime).format(DATE_TIME_FORMAT)
                  : "--"
              }
            />
          </Col>

          <Col xs={24} md={12}>
            <TextBox
              label={t("visitors_registrations.fields.endTime")}
              value={
                record?.endTime
                  ? dayjs(record?.endTime).format(DATE_TIME_FORMAT)
                  : "--"
              }
            />
          </Col>

          <Col xs={24} md={12}>
            <TextBox
              label={t("visitors_registrations.fields.createdAt")}
              value={
                record?.createdAt
                  ? dayjs(record?.createdAt).format(DATE_FORMAT)
                  : "--"
              }
            />
          </Col>

          <Col xs={24} md={12}>
            <TextBox
              label={t("visitors_registrations.fields.createdBy")}
              value={
                (record?.createdBy as IUser)?.name ||
                (record?.createdBy as IUser)?.username ||
                "--"
              }
            />
          </Col>

          <Col xs={24} md={12}>
            <TextBox
              label={t("visitors_registrations.fields.status")}
              value={
                VISITOR_REGISTRATION_STATUS_LIST(t).find(
                  (item) => item.value === record?.status
                )?.label
              }
            />
          </Col>

          <Col xs={24} md={12} />

          <Col xs={24}>
            <PageHeader
              backIcon={null}
              style={{ padding: 0 }}
              title={
                <Text
                  style={{
                    fontSize: 16,
                  }}
                >
                  {t("visitors_registrations.visitorList.titles.list")}
                </Text>
              }
            />
          </Col>

          <Col xs={24}>
            <VisitorTable
              visitorList={visitorList}
              registrationStatus={record?.status}
            />
          </Col>

          <Col xs={24}>
            <Space direction="vertical" style={{ width: "100%" }}>
              {hasSecurityRole ? (
                <>
                  <Text
                    style={{
                      fontWeight: 600,
                    }}
                  >
                    {t("visitors_registrations.fields.comment")}
                  </Text>
                  <Input.TextArea
                    rows={4}
                    style={{ width: "100%" }}
                    disabled={!canApproveOrReject}
                    value={comment}
                    onChange={(evt) => setComment(evt.target.value)}
                  />
                </>
              ) : (
                <TextBox
                  label={t("visitors_registrations.fields.comment")}
                  labelStyles={{
                    fontWeight: 600,
                    color: "rgba(0, 0, 0, 0.85)",
                  }}
                  value={record?.comment || ""}
                  valueStyles={{
                    minHeight: 66,
                  }}
                />
              )}
            </Space>
          </Col>

          <Col xs={24}>
            <Row>
              <Col flex="auto"></Col>
              <Col flex={0}>
                {hasSecurityRole && (
                  <Space>
                    <Popconfirm
                      title={t("visitors_registrations.confirm.wantToReject")}
                      onConfirm={() => doApproveOrReject(record!!, false)}
                      onCancel={() => null}
                      okText={t("visitors_registrations.confirm.agree")}
                      cancelText={t("buttons.cancel")}
                      okType="primary"
                      disabled={!canApproveOrReject}
                    >
                      <Button danger disabled={!canApproveOrReject}>
                        {t("visitors_registrations.buttons.reject")}
                      </Button>
                    </Popconfirm>

                    <Popconfirm
                      title={t("visitors_registrations.confirm.wantToApprove")}
                      onConfirm={() => doApproveOrReject(record!!, true)}
                      onCancel={() => null}
                      okText={t("visitors_registrations.confirm.agree")}
                      cancelText={t("buttons.cancel")}
                      okType="primary"
                      disabled={!canApproveOrReject}
                    >
                      <Button type="primary" disabled={!canApproveOrReject}>
                        {t("visitors_registrations.buttons.approve")}
                      </Button>
                    </Popconfirm>
                  </Space>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Show>
    </>
  );
};
