import {
  Col,
  Create,
  Form,
  Input,
  Row,
  Select,
  useForm,
  useSelect,
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useTranslate } from "@pankod/refine-core";

import { PASSWORD_PLACEHOLDER, ROLE_LIST } from "config";
import { IDepartment, IUser, IUserRoleCode } from "interfaces";
import { fuzzySearch } from "utils/searchHelper";
import { toLowerCaseNonAccentVietnamese } from "utils/vietnameseHelper";

export const UserCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const {
    formProps: { form, ...restFormProps },
    saveButtonProps,
    formLoading,
  } = useForm<IUser>({
    errorNotification: {
      type: "error",
      message: t("users.notification.createError"),
    },
    successNotification: {
      type: "success",
      message: t("users.notification.createSuccess"),
    },
  });

  const roleValue: IUserRoleCode = Form.useWatch("role", form);

  const {
    selectProps: { showSearch, onSearch, ...restSelectProps },
  } = useSelect<IDepartment>({
    resource: "departments",
    optionLabel: "name",
    pagination: {
      current: 1,
      pageSize: 99999, // use the large PageSize to get full
    },
  });

  return (
    <Create saveButtonProps={saveButtonProps} isLoading={formLoading}>
      <Form form={form} {...restFormProps} layout="vertical">
        <Row gutter={[16, 24]}>
          <Col xs={24} md={12}>
            <Form.Item
              label={t("users.fields.role")}
              name="role"
              rules={[
                {
                  required: true,
                  message: t("users.errors.roleRequired"),
                },
              ]}
            >
              <Select options={ROLE_LIST(t)} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              label={t("users.fields.username")}
              name="username"
              rules={[
                {
                  required: true,
                  message: t("users.errors.usernameRequired"),
                },
              ]}
            >
              <Input autoComplete="username" />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              label={t("users.fields.password")}
              name="password"
              rules={[
                {
                  required: true,
                  message: t("users.errors.passwordRequired"),
                },
              ]}
            >
              <Input.Password
                placeholder={PASSWORD_PLACEHOLDER}
                autoComplete="new-password"
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              label={t("users.fields.confirmPassword")}
              name="confirmPassword"
              rules={[
                {
                  required: true,
                  message: t("users.errors.confirmPasswordRequired"),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(t("users.errors.confirmPasswordNotMatch"))
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                placeholder={PASSWORD_PLACEHOLDER}
                autoComplete="new-password"
              />
            </Form.Item>
          </Col>

          {(roleValue === "DEPARTMENT" ||
            roleValue === "SECURITY_DEPARTMENT") && (
            <Col xs={24} md={12}>
              <Form.Item
                label={t("users.fields.department")}
                name="departmentId"
                rules={[
                  {
                    required: true,
                    message: t("users.errors.departmentRequired"),
                  },
                ]}
              >
                <Select
                  {...restSelectProps}
                  showSearch={true}
                  filterOption={(input, option) =>
                    fuzzySearch(
                      toLowerCaseNonAccentVietnamese(
                        String(option?.label ?? "")
                      ),
                      toLowerCaseNonAccentVietnamese(input)
                    )
                  }
                />
              </Form.Item>
            </Col>
          )}

          <Col xs={24} md={12} />
        </Row>
      </Form>
    </Create>
  );
};
